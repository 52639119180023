import { useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { axiosInstance } from "../../auth/Auth";

export default function ForgotPassword() {
    const [email, setEmail] = useState("");
    const [sending, setSending] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleSubmit = async event => {
        event.preventDefault();
        setSending(true);
        try {
            const response = await axiosInstance.post("/forgot_password/", { email: email, domain: window.location.origin });
            setEmailSent(true);
        } catch (error) {
            setEmailSent(false);
            if (error?.response?.status === 404) setErrorMessage("There is no user associated with this email.");
            else setErrorMessage("Unexpected error occured. Please try again later or contact us at tech@radioanalyzer.com");
        }
        setSending(false);
    };
    return (
        <>
            <h1 className="text-light fw-bold mb-3 mt-xl-0 lh-sm fs-2 text-center text-xl-start">Forgot your password?</h1>
            <p className=" mb-4 text-light text-center text-xl-start">Enter your email address below, and we'll email instructions for setting a new one</p>
            <Form onSubmit={handleSubmit} className=" mb-auto mb-xl-0">
                <InputGroup className="mb-4 ">
                    <InputGroup.Text className="text-blue bg-beige color-ra-dark-blue fs-4 p-2">@</InputGroup.Text>
                    <Form.Control
                        className=" rounded-end"
                        size="lg"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        required
                        type="email"
                        name="email"
                        placeholder="email@example.com"
                    />
                </InputGroup>
                <Row className=" row-cols-1 row-cols-xl-2 gy-2">
                    <Col>
                        <NavLink to={"/login"} end className="btn btn-ra-light text-uppercase fw-bold w-100 fs-5 btn-sm">
                            Go Back
                        </NavLink>
                    </Col>
                    <Col className=" order-first order-xl-last">
                        <Button className="btn-ra-yellow text-uppercase fw-bold w-100 fs-5" size="sm" type={"submit"} disabled={sending}>
                            Send Email
                        </Button>
                    </Col>
                </Row>
                <div className="position-relative mt-2" data-bs-theme="dark">
                    {emailSent ? (
                        <label className=" text-success-emphasis position-absolute">Email sent successfully!</label>
                    ) : (
                        <label className="text-warning position-absolute">{errorMessage}</label>
                    )}
                </div>
            </Form>
        </>
    );
}
