import { Col, Form } from "react-bootstrap";
import RATooltip from "./RATooltip";

export default function MinMaxSpinsPicker({ filterData, setFilterData, name, inputOnly = false }) {
    const input = (
        <Col className=" d-flex align-items-center column-gap-2">
            <span>MIN</span>
            {renderInputField("minSpins")}
            <span>MAX</span>
            {renderInputField("maxSpins")}
        </Col>
    );

    if (inputOnly) return input;

    function renderInputField(field) {
        return (
            <Form.Control
                type="number"
                className=" d-inline-block spins-input"
                value={filterData[field].toString()}
                onChange={e =>
                    setFilterData({
                        ...filterData,
                        [field]: Number(e.target.value),
                    })
                }
                min={0}
            />
        );
    }

    return (
        <Form.Group as={Col}>
            <Form.Label className="d-flex column-gap-1">
                Spins
                <RATooltip>
                    You can filter the number of spins per song in the timeframe queried, e.g., when choosing "last month" and "MIN - 2", "MAX - 40", the list
                    will filter to only include songs played between 2 and 40 times over the last month. If left blank, "MIN" will default to "0" and MAX will
                    default to "Infinite".
                </RATooltip>
            </Form.Label>
            {input}
        </Form.Group>
    );
}
