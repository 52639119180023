import { Button, Col, Row } from "react-bootstrap";
import { getSongScoreColorCss, toAutoDecimals } from "../HelperFunctions";
import { BarChartLineFill } from "react-bootstrap-icons";
import SongCategoryPicker from "./SongCategoryPicker";

export default function PlaylistAutopilotRow({ item, virtualizer, virtualRow, setTrendSong, onChange }) {
    return (
        <Row
            data-index={virtualRow?.index}
            ref={virtualizer?.measureElement}
            style={{ transform: `translateY(${virtualRow?.start - virtualizer?.options.scrollMargin}px)` }}
            className={`py-1 border-bottom ${virtualizer && "position-absolute"} w-100 hoverable`}>
            <Col xs={2} className=" align-content-center">
                <Row className=" align-items-center text-center">
                    <Col className="text-center px-1">{item.song_category_name}</Col>
                    <Col className="text-center px-1">
                        {!!onChange ? (
                            <SongCategoryPicker item={item} handleChange={onChange} />
                        ) : (
                            <span className={item.song_category_name === item.song_category_move_name ? "text-body-tertiary" : ""}>
                                {item.song_category_move_name}
                            </span>
                        )}
                    </Col>
                </Row>
            </Col>
            <Col xs={3} className=" align-content-center">
                <Row className=" align-items-center">
                    <Col xs={12} className="fw-bold">
                        {item.song.artist}
                    </Col>
                    <Col xs={12}>{item.song.title}</Col>
                </Row>
            </Col>
            <Col className=" align-content-center">
                <Row className="playlist-autopilot-short-cols align-items-center">
                    <Col className=" align-content-center">
                        <Row className=" align-items-center h-100">
                            <Col xs={12} className="fw-bold text-center">
                                {item.spins}
                            </Col>
                            <Col xs={12} className=" text-center">
                                {item.total_spins}
                            </Col>
                        </Row>
                    </Col>

                    <Col className=" d-flex justify-content-center">
                        <div className={`score ${getSongScoreColorCss(toAutoDecimals(item.song_score, 1), true)}`}>{toAutoDecimals(item.song_score, 1)}</div>
                    </Col>
                    <Col className=" d-flex justify-content-center">
                        <div className={`score ${getSongScoreColorCss(toAutoDecimals(item.song_score_month, 1), true)}`}>
                            {toAutoDecimals(item.song_score_month, 1)}
                        </div>
                    </Col>
                    <Col className="text-center fw-bold">{toAutoDecimals(item.burn, 1) ?? "-"}</Col>
                    <Col className="text-center">{item.song.release_year ?? "-"}</Col>
                    <Col className="text-center">
                        <Button
                            className="py-1"
                            variant="ra-yellow"
                            size="sm"
                            onClick={e => {
                                e.stopPropagation();
                                setTrendSong(item.song);
                            }}>
                            <BarChartLineFill /> Trend
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
