import { Col, Container, Row, Stack } from "react-bootstrap";
import { CaretDownFill, CaretUpFill } from "react-bootstrap-icons";
import RATooltip from "../RATooltip";
import SongScoreTooltip from "../musicTest/SongScoreTooltip";
import BurnTooltip from "./BurnTooltip";

export default function PlaylistAutopilotHeader({ sortConfig, requestSort }) {
    return (
        <Container fluid className="playlist-autopilot-mw">
            <Row className="bg-ra-dark-blue text-white fw-bold py-1 rounded-2 fs-8-xs table-header user-select-none h-100">
                <Col xs={2} className=" align-content-center">
                    <Row className="h-100">
                        <Col onClick={() => requestSort("song_category", "ascending")} className=" align-content-center px-1">
                            <Stack direction="horizontal" className="justify-content-center text-center">
                                <span className=" d-flex flex-wrap-reverse column-gap-1 justify-content-center offset-1 align-items-center">
                                    Category Name
                                    <RATooltip>
                                        <p>Songs are sorted based on age, market exposure and spins on your own station:</p>
                                        <p>
                                            <b>New</b> – New Music in your market, usually up to about 1,5-2 months old.
                                        </p>
                                        <p>
                                            <b>Power</b> – your best current songs.
                                        </p>
                                        <p>
                                            <b>Recurrent</b> – songs you play from the past few years.
                                        </p>
                                        <p>
                                            <b>2010s to 1960s</b> – everything else sorted by decades.
                                        </p>
                                        <p className="mb-0">
                                            <b>Levels 1</b> is your most played songs in each category and <b>Level 2</b> are the songs you play less.
                                        </p>
                                    </RATooltip>
                                </span>
                                <span className="col-1">
                                    {sortConfig?.key === "song_category" && (sortConfig.direction === "descending" ? <CaretDownFill /> : <CaretUpFill />)}
                                </span>
                            </Stack>
                        </Col>
                        <Col onClick={() => requestSort("song_category_move", "ascending")} className=" align-content-center px-1">
                            <Stack direction="horizontal" className="justify-content-center text-center">
                                <span className=" d-flex flex-wrap-reverse column-gap-1 justify-content-center offset-1 align-items-center">
                                    Category Move
                                    <RATooltip>
                                        Move suggestions are based on song performance, trends and age. Songs moved to level 3 should be rested for a while or
                                        removed.
                                    </RATooltip>
                                </span>
                                <span className="col-1">
                                    {sortConfig?.key === "song_category_move" && (sortConfig.direction === "descending" ? <CaretDownFill /> : <CaretUpFill />)}
                                </span>
                            </Stack>
                        </Col>
                    </Row>
                </Col>
                <Col xs={3} className=" align-content-center">
                    <Row className=" h-100">
                        <Col xs={12} onClick={() => requestSort("song.artist")} className=" align-content-center">
                            <Stack direction="horizontal" className="justify-content-center justify-content-lg-start">
                                <span>Artist</span>
                                <span className="col-1">
                                    {sortConfig?.key === "song.artist" && (sortConfig.direction === "descending" ? <CaretDownFill /> : <CaretUpFill />)}
                                </span>
                            </Stack>
                        </Col>
                        <Col xs={12} onClick={() => requestSort("song.title")} className=" align-content-center">
                            <Stack direction="horizontal" className="justify-content-center justify-content-lg-start">
                                <span>Title</span>
                                <span className="col-1">
                                    {sortConfig?.key === "song.title" && (sortConfig.direction === "descending" ? <CaretDownFill /> : <CaretUpFill />)}
                                </span>
                            </Stack>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row className="playlist-autopilot-short-cols h-100">
                        <Col className=" align-content-center">
                            <Row className=" h-100">
                                <Col xs={12} onClick={() => requestSort("spins")} className=" align-content-center">
                                    <Stack direction="horizontal" className="justify-content-center">
                                        <span className="offset-1">Spins</span>
                                        <span className="col-1">
                                            {sortConfig?.key === "spins" && (sortConfig.direction === "descending" ? <CaretDownFill /> : <CaretUpFill />)}
                                        </span>
                                    </Stack>
                                </Col>
                                <Col xs={12} onClick={() => requestSort("total_spins")} className=" align-content-center">
                                    <Stack direction="horizontal" className="justify-content-center text-center">
                                        <span className="offset-1">Total Spins</span>
                                        <span className="col-1">
                                            {sortConfig?.key === "total_spins" && (sortConfig.direction === "descending" ? <CaretDownFill /> : <CaretUpFill />)}
                                        </span>
                                    </Stack>
                                </Col>
                            </Row>
                        </Col>
                        <Col onClick={() => requestSort("song_score")} className=" align-content-center">
                            <Stack direction="horizontal" className="justify-content-center text-center">
                                <span className=" d-flex flex-wrap-reverse justify-content-center offset-1 align-items-center">
                                    Score week
                                    <SongScoreTooltip />
                                </span>
                                <span className="col-1">
                                    {sortConfig?.key === "song_score" && (sortConfig.direction === "descending" ? <CaretDownFill /> : <CaretUpFill />)}
                                </span>
                            </Stack>
                        </Col>
                        <Col onClick={() => requestSort("song_score_month")} className=" align-content-center">
                            <Stack direction="horizontal" className="justify-content-center text-center">
                                <span className=" d-flex flex-wrap-reverse justify-content-center offset-1 align-items-center">
                                    Score month
                                    <SongScoreTooltip />
                                </span>
                                <span className="col-1">
                                    {sortConfig?.key === "song_score_month" && (sortConfig.direction === "descending" ? <CaretDownFill /> : <CaretUpFill />)}
                                </span>
                            </Stack>
                        </Col>
                        <Col onClick={() => requestSort("burn")} className=" align-content-center">
                            <Stack direction="horizontal" className="justify-content-center">
                                <span className=" d-flex flex-wrap-reverse justify-content-center offset-1 column-gap-1 align-items-center">
                                    Burn
                                    <BurnTooltip />
                                </span>
                                <span className="col-1">
                                    {sortConfig?.key === "burn" && (sortConfig.direction === "descending" ? <CaretDownFill /> : <CaretUpFill />)}
                                </span>
                            </Stack>
                        </Col>
                        <Col onClick={() => requestSort("song.release_year")} className=" align-content-center">
                            <Stack direction="horizontal" className="justify-content-center text-center">
                                <span className="offset-1">Release Year</span>
                                <span className="col-1">
                                    {sortConfig?.key === "song.release_year" && (sortConfig.direction === "descending" ? <CaretDownFill /> : <CaretUpFill />)}
                                </span>
                            </Stack>
                        </Col>
                        <Col className="text-center align-content-center">Trend</Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}
