import React from "react";
import ReactDOM from "react-dom/client";
import { router } from "./App";
import { RouterProvider } from "react-router-dom";
import "rsuite/dist/rsuite-no-reset.min.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import "react-toastify/dist/ReactToastify.min.css";
import "./index.css";
import moment from "moment/min/moment-with-locales";

moment.locale(window.navigator.userLanguage || window.navigator.language);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
