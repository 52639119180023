import RATooltip from "../RATooltip";

export default function BurnTooltip() {
    return (
        <RATooltip>
            Burn measures how overplayed a song is for your audience. High burn is an indicator to play it less often - this could be hits that need a break, or
            a new song that is being pushed too early.
        </RATooltip>
    );
}
