import { Col, Container, Figure, Row } from "react-bootstrap";
import Stormy from "../assets/Stormy.png";

export default function Employees() {
    return (
        <Container fluid>
            <Row>
                <Col>
                    <p className="text-center">For a serotonin boost meet our purrfect employees:</p>
                </Col>
            </Row>
            <Row className=" row-cols-2 row-cols-xl-4">
                <Col>
                    <Figure>
                        <Figure.Image src={Stormy} alt="Catployee Picture" />
                        <Figure.Caption className="fw-bold">Stormy, the Analyst</Figure.Caption>
                    </Figure>
                </Col>
                <Col>
                    <Figure>
                        <Figure.Image src={Stormy} alt="Catployee Picture" />
                        <Figure.Caption className="fw-bold">Stormy, the Analyst</Figure.Caption>
                    </Figure>
                </Col>
                <Col>
                    <Figure>
                        <Figure.Image src={Stormy} alt="Catployee Picture" />
                        <Figure.Caption className="fw-bold">Stormy, the Analyst</Figure.Caption>
                    </Figure>
                </Col>
                <Col>
                    <Figure>
                        <Figure.Image src={Stormy} alt="Catployee Picture" />
                        <Figure.Caption className="fw-bold">Stormy, the Analyst</Figure.Caption>
                    </Figure>
                </Col>
            </Row>
        </Container>
    );
}
