import React from "react";
import { CSVLink } from "react-csv";
import { saveAs } from "file-saver";
import { Button } from "react-bootstrap";
import ExcelJS from "exceljs";
import { FiletypeCsv, FiletypeXlsx } from "react-bootstrap-icons";

const ExportData = ({ headers, data = [], filename }) => {
    const exportToXLSX = () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("DataSheet");

        // Define the headers
        worksheet.addRow(headers);

        // Add data to the worksheet
        worksheet.addRows(data);

        // Generate and save the XLSX file
        workbook.xlsx.writeBuffer().then(buffer => {
            const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            saveAs(blob, `${filename}.xlsx`);
        });
    };

    return (
        <>
            <Button
                as={CSVLink}
                variant={"contained"}
                headers={headers}
                data={data}
                className={`p-0 btn-download border-0 ${!data.length && "disabled"}`}
                filename={`${filename}.csv`}
                target="_blank"
                extension=".csv">
                <FiletypeCsv width={30} height={30} />
            </Button>
            <Button variant={"contained"} onClick={exportToXLSX} disabled={!data.length} className={`p-0 btn-download border-0`}>
                <FiletypeXlsx width={30} height={30} />
            </Button>
        </>
    );
};

export default ExportData;
