import { Navigate, isRouteErrorResponse, useRouteError } from "react-router-dom";
import { useAuth } from "../auth/Auth";

export default function ErrorBoundary() {
    const { onLogout } = useAuth();
    const error = useRouteError();

    if (isRouteErrorResponse(error)) {
        // If we get unauthenticated error, we redirect to login page
        if (error.status === 401) {
            onLogout();
        }

        // If we get generic server error, we redirect to error page
        if (error.status === 500) {
            return <Navigate to={"/error"} />;
        }
    }

    // If we get unauthenticated error, we redirect to login page
    if (error?.response?.status === 401) {
        onLogout();
    }

    // If we get generic server error, we redirect to error page
    if (error?.response?.status === 500) {
        return <Navigate to={"/error"} />;
    }

    // Otherwise we show error, TODO
    return <span>Error loading: {error.message}</span>;
}
