import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Col, Container, Form, Row } from "react-bootstrap";
import { axiosInstance } from "../../auth/Auth";
import { toast } from "react-toastify";

const Settings = () => {
    const navigate = useNavigate();

    const formRef = useRef(null);

    const [resetting, setResetting] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [oldpassword, setOldpassword] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");

    const lengthValid = password.length >= 8;
    const uppercaseValid = /[A-Z]/.test(password);
    const lowercaseValid = /[a-z]/.test(password);
    const digitValid = /\d/.test(password);
    const passwordsMatch = password === password2;
    const isValid = lengthValid && uppercaseValid && lowercaseValid;

    const handleSubmit = async event => {
        event.preventDefault();
        setResetting(true);

        try {
            const response = await axiosInstance.put("/users/update_password/", {
                old_password: oldpassword,
                new_password_1: password,
                new_password_2: password2,
            });
            toast.success("Your password was reset successfully");
            navigate("/login", { replace: true });
        } catch (error) {
            if (error?.response?.status === 400 || error?.response?.status === 401) setErrorMessage(error.response.data.detail);
            else setErrorMessage("Unexpected error occured. Please try again later or contact us at tech@radioanalyzer.com");
        }
        setResetting(false);
    };

    return (
        <Container fluid className={"p-4"}>
            <Row className="bg-white p-4 ">
                <Col>
                    <h2 className="text-center text-md-start">CHANGE PASSWORD</h2>
                    <p className="text-center text-md-start">All three fields are necessary.</p>
                    <Form onSubmit={handleSubmit} ref={formRef} className=" mb-auto mb-xl-0" noValidate>
                        <Col className="col-lg-3 col-12">
                            <Form.Control
                                className="mb-3"
                                required
                                value={oldpassword}
                                type="password"
                                placeholder="Old password"
                                name={"old_password"}
                                onChange={e => setOldpassword(e.target.value)}
                            />

                            <Form.Control
                                className="mb-3"
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                required
                                type="password"
                                name="password"
                                placeholder="New password"
                                isValid={isValid}
                            />
                            <Form.Control
                                className="mb-3"
                                value={password2}
                                onChange={e => setPassword2(e.target.value)}
                                required
                                minLength={6}
                                type="password"
                                name="password2"
                                placeholder="Confirm new password"
                                isValid={password && passwordsMatch}
                            />

                            <Col>
                                <ul className=" mb-4 c-red" data-bs-theme={"dark"}>
                                    <li className={lengthValid ? "text-success" : ""}>Must contain at least 8 characters</li>
                                    <li className={uppercaseValid ? "text-success" : ""}>Must contain at least 1 uppercase letter</li>
                                    <li className={lowercaseValid ? "text-success" : ""}>Must contain at least 1 lowercase letter</li>
                                    <li className={digitValid ? "text-success" : ""}>Must contain at least 1 digit</li>
                                    <li className={password && passwordsMatch ? "text-success" : ""}>Passwords must match</li>
                                </ul>
                            </Col>

                            <Col className="col-12 col-xl-8 text-center text-md-start">
                                <div className="m-3" data-bs-theme="dark">
                                    {errorMessage && <label className="c-red text-uppercase">{errorMessage}</label>}
                                </div>
                                <Button
                                    className={"btn-ra-dark-blue text-light fw-semibold text-uppercase text-center"}
                                    variant={"warning"}
                                    type={"submit"}
                                    disabled={resetting || !isValid || !passwordsMatch}>
                                    Update new password
                                </Button>
                            </Col>
                        </Col>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default Settings;
