import { Col, Container, Row } from "react-bootstrap";

const errors = {
    404: "Sorry, it looks like you don't have any data for your selection. Please double check and try again.",
    500: "Sorry, something went wrong. Please try again.",
};

export default function ErrorResponse({ errorCode, customMessage = null }) {
    return (
        <Container className="error-container">
            <Row className=" justify-content-center">
                <Col xl={8} className=" text-center">
                    <h3 className="error-message">{customMessage ?? errors[errorCode] ?? errors["500"]}</h3>
                    <p className=" mt-4">
                        Contact us at{" "}
                        <a
                            className="fw-bold color-ra-dark-blue link-underline-dark link-underline-opacity-0 link-underline-opacity-75-hover"
                            href={`mailto:support@radioanalyzer.com?subject=${encodeURIComponent("Music Test")}`}>
                            support@radioanalyzer.com
                        </a>{" "}
                        if you need help.
                    </p>
                </Col>
            </Row>
        </Container>
    );
}
