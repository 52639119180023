import RATooltip from "../RATooltip";

export default function SongScoreTooltip() {
    return (
        <RATooltip>
            <p>Song Score ranks listener reactions to your music. Here is one suggested implementation filter:</p>
            <p>
                <span className="color-square bg-score-worst"></span> 1-3: Rest or drop (depending on trend and sound)
            </p>
            <p>
                <span className="color-square bg-score-worst"></span> 3-4.5: Fewer spins or drop
            </p>
            <p>
                <span className="color-square bg-score-worse"></span> 4.5-5: Stay on a good trend or a sound variety pick
            </p>
            <p>
                <span className="color-square bg-score-middle"></span> 5-6: Stay (move up new songs at ca 5.5)
            </p>
            <p>
                <span className="color-square bg-score-better"></span> 6-7: Slightly more spins on a steady trend/plateau
            </p>
            <p className="mb-0">
                <span className="color-square bg-score-best"></span> 7-10: Significantly more spins on a steady trend/plateau
            </p>
        </RATooltip>
    );
}
