import { Card, Col } from "react-bootstrap";
import { Clock, PersonFill } from "react-bootstrap-icons";
import moment from "moment/min/moment-with-locales";

export default function BlogPost({ author, imgSrc, datePosted, blogUrl, title }) {
    return (
        <Col>
            <Card className="h-100 w-100">
                <Card.Img variant="top" src={imgSrc} />
                <Card.Body className=" d-flex flex-column align-items-center">
                    <Card.Title className=" flex-grow-1">{title}</Card.Title>
                    <Card.Text className="text-body-secondary">
                        <PersonFill /> {author}
                        <br />
                        <Clock /> {moment(datePosted).format("LL")}
                    </Card.Text>
                    <a href={blogUrl} className="btn btn-ra-dark-blue" target="_blank" rel="noreferrer">
                        Read full article
                    </a>
                </Card.Body>
            </Card>
        </Col>
    );
}
