import React from "react";
import { Button } from "react-bootstrap";

export default function AnalyzeButton({ disabled, label = "Analyze" }) {
    return (
        <Button type="submit" className="btn-ra-yellow text-uppercase px-5 py-1" disabled={disabled}>
            {label}
        </Button>
    );
}
