import React from "react";
import { Outlet } from "react-router-dom";
import { AuthProvider } from "../auth/Auth";
import { ToastContainer, Slide } from "react-toastify";

function AuthLayout() {
    return (
        <AuthProvider>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                pauseOnHover={false}
                draggable
                transition={Slide}
                theme="light"
            />
            <Outlet />
        </AuthProvider>
    );
}

export default AuthLayout;
