import { forwardRef, useImperativeHandle, useState } from "react";
import { Button, Col, Collapse, Container, Form, Row, Stack, InputGroup } from "react-bootstrap";
import { CaretDownFill, CaretUpFill } from "react-bootstrap-icons";
import AnalyzeButton from "./AnalyzeButton";
import BackToTopButton from "./BackToTopButton";
import ErrorResponse from "./ErrorResponse";
import ExportData from "./ExportData";
import LoadingIndicator from "./LoadingIndicator";
import ScreenshotButton from "./ScreenshotButton";
import { Search } from "react-bootstrap-icons";
import PropTypes from "prop-types";

const ModulePageTitle = ({ children, className }) => (
    <Row>
        <Col className="p-4 pb-0 bg-white">
            <h1 className={`text-uppercase ${className}`}>{children}</h1>
        </Col>
    </Row>
);
ModulePageTitle.propTypes = { children: PropTypes.node.isRequired };

const ModulePageContainerInput = ({ children, handleFormSubmit, disabled, submitText }) => (
    <InputSelection handleFormSubmit={handleFormSubmit} disabled={disabled} submitText={submitText}>
        {children}
    </InputSelection>
);
ModulePageContainerInput.propTypes = { children: PropTypes.node.isRequired, handleFormSubmit: PropTypes.func.isRequired };

ModulePage.InputSelection = ModulePageContainerInput;
ModulePage.Title = ModulePageTitle;
ModulePage.DataContent = DataContent;
export function ModulePage({ children }) {
    return (
        <Container fluid className="p-4 flex-column d-flex h-100">
            <BackToTopButton />
            {children}
        </Container>
    );
}
ModulePage.propTypes = { children: PropTypes.node.isRequired };

const ModuleInputSelectionMain = ({ children }) => (
    <Col xs={12} xl>
        <Row className=" gy-3">{children}</Row>
    </Col>
);
ModuleInputSelectionMain.propTypes = { children: PropTypes.node.isRequired };

const ModuleInputSelectionAdvanced = forwardRef(({ children, moduleName }, ref) => {
    const [advancedOpen, setAdvancedOpen] = useState(false);
    useImperativeHandle(ref, () => ({
        closeAdvanced() {
            setAdvancedOpen(false);
        },
        openAdvanced() {
            setAdvancedOpen(true);
        },
    }));
    return (
        <>
            <Col xs={12} className=" order-xl-last"></Col>
            <Col xs={12} xl={10} className="order-xl-last my-3 mb-xl-0">
                <Button
                    variant="contained"
                    onClick={() => setAdvancedOpen(!advancedOpen)}
                    className="border-0"
                    aria-controls={`${moduleName}-advanced`}
                    aria-expanded={advancedOpen}>
                    Advanced {advancedOpen ? <CaretUpFill /> : <CaretDownFill />}
                </Button>
                <Collapse in={advancedOpen}>
                    <Row id={`${moduleName}-advanced`} className="gy-3 mt-0">
                        {children}
                    </Row>
                </Collapse>
            </Col>
        </>
    );
});
ModuleInputSelectionAdvanced.propTypes = { children: PropTypes.node.isRequired, moduleName: PropTypes.string.isRequired };

InputSelection.Main = ModuleInputSelectionMain;
InputSelection.Advanced = ModuleInputSelectionAdvanced;
export function InputSelection({ children, handleFormSubmit, disabled, submitText }) {
    return (
        <>
            <Row>
                <Col className="bg-white p-4 pt-0">
                    <Form onSubmit={handleFormSubmit} className="row gy-3">
                        {children}
                        <Col xs={12} xl={"auto"} className="text-center mt-xl-auto">
                            <AnalyzeButton disabled={disabled} label={submitText} />
                        </Col>
                    </Form>
                </Col>
            </Row>
            <hr className="m-2"></hr>
        </>
    );
}
InputSelection.propTypes = { children: PropTypes.node.isRequired, handleFormSubmit: PropTypes.func.isRequired };

const DataContentPlaceholder = ({ children, show }) => show && <>{children}</>;
DataContentPlaceholder.propTypes = { children: PropTypes.node.isRequired, show: PropTypes.bool.isRequired };
DataContent.Placeholder = DataContentPlaceholder;
export function DataContent({ children, loading, errorCode, multiPanel = false, customErrorMessage = null }) {
    return (
        <Row className=" flex-grow-1">
            <Col className={`${multiPanel ? "p-0" : "bg-white p-4"} d-flex flex-column`}>
                {loading ? (
                    <div className={`d-flex flex-grow-1 ${multiPanel ? "bg-white" : ""}`}>
                        <LoadingIndicator width={75} />
                    </div>
                ) : errorCode ? (
                    <div className={`d-flex flex-grow-1 align-items-center ${multiPanel ? "bg-white" : ""}`}>
                        <ErrorResponse errorCode={errorCode} customMessage={customErrorMessage} />
                    </div>
                ) : (
                    children
                )}
            </Col>
        </Row>
    );
}

export function SearchWithDownloadRow({ filterData, setFilterData, csvHeaders, data, filename, placeholder = "Search by artist, title or release year" }) {
    return (
        <Stack direction="horizontal" gap={3} className=" mb-2 flex-wrap-reverse">
            <Col xs xl={5}>
                <InputGroup>
                    <InputGroup.Text className="input-icon">
                        <Search />
                    </InputGroup.Text>
                    <Form.Control
                        value={filterData.searchString}
                        onChange={e => setFilterData({ ...filterData, searchString: e.target.value })}
                        placeholder={placeholder}
                    />
                </InputGroup>
            </Col>
            <Col xs={"auto"} className="ms-auto">
                <Stack direction="horizontal" gap={1}>
                    <span className="me-1 d-none d-md-inline-block">Download:</span>
                    <ExportData data={data} headers={csvHeaders} filename={filename} />
                </Stack>
            </Col>
        </Stack>
    );
}
SearchWithDownloadRow.propTypes = {
    filterData: PropTypes.any.isRequired,
    setFilterData: PropTypes.func.isRequired,
    data: PropTypes.any.isRequired,
    csvHeaders: PropTypes.array.isRequired,
    filename: PropTypes.string.isRequired,
};

export function DescriptionWithDownloadRow({ children, csvHeaders, data, filename = "", screenshotContainer }) {
    return (
        <Row className="row-gap-3 mb-2">
            <Col xs={12} xl className=" align-self-center">
                {children}
            </Col>
            <Col xs={12} xl={"auto"} className=" order-first order-xl-last">
                <Stack direction="horizontal" gap={1}>
                    {csvHeaders && <span className="me-1 d-none d-md-inline-block">Download:</span>}
                    {csvHeaders && <ExportData data={data} headers={csvHeaders} filename={filename} />}
                    {screenshotContainer && <ScreenshotButton disabled={!data} fileName={filename} container={screenshotContainer} />}
                </Stack>
            </Col>
        </Row>
    );
}

export function SoleDescriptionRow({ children }) {
    return <div className=" mb-2">{children}</div>;
}
SoleDescriptionRow.propTypes = { children: PropTypes.node.isRequired };

const TableHeader = ({ children }) => <>{children}</>;
const TableBody = ({ children }) => <Container fluid>{children}</Container>;
RATable.Header = TableHeader;
RATable.Body = TableBody;
export function RATable({ children }) {
    return <>{children}</>;
}

const DashboardTitle = ({ children }) => <h4 className="fw-bold text-uppercase mb-1 px-4 d-flex column-gap-1">{children}</h4>;
const DashboardDataDescription = ({ children, containerRef, dataReady = false, fileName = "" }) => (
    <Col xs={12} className="px-4 mb-2">
        <Row className=" align-items-center">
            <Col>{children}</Col>
            {containerRef && (
                <Col xs={"auto"} className=" align-self-end">
                    <ScreenshotButton container={containerRef} fileName={fileName} disabled={!dataReady} />
                </Col>
            )}
        </Row>
    </Col>
);
const DashboardDataContent = ({ children }) => (
    <Col xs={12} className="px-4">
        {children}
    </Col>
);
DashboardPanel.Title = DashboardTitle;
DashboardPanel.Description = DashboardDataDescription;
DashboardPanel.DataContent = DashboardDataContent;
export function DashboardPanel({ children, containerRef, className = "" }) {
    return (
        <Row className={`bg-white py-3 ${className}`} ref={containerRef}>
            {children}
        </Row>
    );
}

export function VirtualizedWindowContainer({ children, containerRef, virtualizer, className = "" }) {
    return (
        <div ref={containerRef}>
            <Container fluid className={`position-relative ${className}`} style={{ height: virtualizer.getTotalSize() }}>
                {children}
            </Container>
        </div>
    );
}
