import { Col, Container, Row } from "react-bootstrap";
import Employees from "../../components/Employees";
import { NavLink } from "react-router-dom";

export default function NotFound() {
    return (
        <Container className="text-center align-self-center">
            <Row>
                <Col>
                    <h1 className="h1-lg">Page Not Found</h1>
                    <p>Oh no, the dreaded 404. Let’s get you back on track.</p>
                    <p>
                        Please contact{" "}
                        <a
                            className="fw-bold color-ra-dark-blue link-underline-dark link-underline-opacity-0 link-underline-opacity-75-hover"
                            href={`mailto:support@radioanalyzer.com?subject=${encodeURIComponent("Page Not Found")}`}>
                            support@radioanalyzer.com
                        </a>{" "}
                        OR
                    </p>
                    <NavLink to={"/dashboard"} className="btn btn-ra-dark-blue px-5 mb-5">
                        Return to Homepage
                    </NavLink>
                    <Employees />
                </Col>
            </Row>
        </Container>
    );
}
