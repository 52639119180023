import React, { Suspense, useEffect, useMemo, useRef, useState, useCallback } from "react";
import { Await, useRouteLoaderData } from "react-router-dom";
import "../MusicTest/styles.css";
import MusicTestHeader from "../../components/musicTest/MusicTestHeader";
import MusicTestRow from "../../components/musicTest/MusicTestRow";
import { Col, Form, InputGroup, Stack } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { DateRangePicker } from "rsuite";
import moment from "moment/min/moment-with-locales";
import { useWindowVirtualizer } from "@tanstack/react-virtual";
import { Search } from "react-bootstrap-icons";
import { axiosInstance } from "../../auth/Auth";
import isAfter from "date-fns/isAfter";
import useSortableData from "../../components/useSortableData";
import { convertMomentToDatepickerFormat, findRadioDayparts, formatDaypartText } from "../../components/HelperFunctions";
import { addDays, addMonths, endOfWeek, startOfWeek } from "date-fns";
import RATooltip from "../../components/RATooltip";
import { useMusicTestTrendModal } from "../../components/TrendOverTime";
import { DataContent, DescriptionWithDownloadRow, InputSelection, ModulePage, VirtualizedWindowContainer } from "../../components/pageSelections";
import PageDesciption from "../../components/PageDesciption";
import MinMaxSpinsPicker from "../../components/MinMaxSpinsPicker";
import EmptyTableRow from "../../components/EmptyTableRow";

const MusicTesting = () => {
    const { radios, dayparts } = useRouteLoaderData("root");
    const stationsTypeahead = useRef(null);
    const moduleName = "MusicTesting";
    const [formData, setFormData] = useState({
        defaultStations: localStorage.getItem("musicTestStations") ? JSON.parse(localStorage.getItem("musicTestStations")) : [],
        stations: [],
        defaultDaypart: localStorage.getItem("musicTestDaypart") ? JSON.parse(localStorage.getItem("musicTestDaypart")) : 1,
        daypart: "placeholder",
        defaultDemo: localStorage.getItem("musicTestDemo")
            ? JSON.parse(localStorage.getItem("musicTestDemo"))
            : [{ name: "Online: All", demo_id: 1, listener_group: 0, data_type: 1 }],
        demo: [{ name: "Online: All", demo_id: 1, listener_group: 0, data_type: 1 }],
        dateRange: null,
    });
    const [filterData, setFilterData] = useState({
        limitTrend: true,
        hideDeltas: true,
        hideInvalid: true,
        minSpins: 0,
        maxSpins: 0,
        searchString: "",
    });
    const { setTrendSong, MusicTestTrendModalComponent } = useMusicTestTrendModal();
    const tableContainerRef = useRef(null);
    const [latestCalculatedDates, setLatestCalculatedDates] = useState([]);
    const [latestCalculatedDate, setLatestCalculatedDate] = useState(null);
    const [advancedOpen, setAdvancedOpen] = useState(false);
    const [burnLocked, setBurnLocked] = useState(false);
    const [musicTestData, setMusicTestData] = useState(null);
    const [dataLoading, setDataLoading] = useState(false);
    const [errorCode, setErrorCode] = useState(null);
    const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
    const isCombined = musicTestData?.radio_ids.length > 1;

    const musicTestDataMemo = useMemo(() => {
        if (musicTestData) {
            let filteredData = musicTestData.data;
            if (filterData.minSpins > 0) {
                filteredData = filteredData.filter(x => x.combined.spins >= filterData.minSpins);
            }
            if (filterData.maxSpins > 0) {
                filteredData = filteredData.filter(x => x.combined.spins <= filterData.maxSpins);
            }
            if (filterData.hideInvalid) {
                filteredData = filteredData.filter(x => x.combined.valid);
            }
            if (filterData.searchString) {
                filteredData = filteredData.filter(
                    x =>
                        x.song.artist.toLowerCase().includes(filterData.searchString.toLowerCase().trim()) ||
                        x.song.title.toLowerCase().includes(filterData.searchString.toLowerCase().trim()) ||
                        x.song.release_year?.toString().includes(filterData.searchString.trim())
                );
            }
            return filteredData;
        }
    }, [filterData.hideInvalid, filterData.maxSpins, filterData.minSpins, musicTestData, filterData.searchString]);

    const predefinedRanges = useMemo(() => {
        if (latestCalculatedDates?.length && formData.daypart !== "placeholder") {
            const baseDate = latestCalculatedDates.find(x => x.daypart_id === formData.daypart).latest_date;
            return [
                {
                    label: "Latest week",
                    value: [
                        moment(baseDate).subtract(1, "week").add(1, "minute").startOf("isoWeek").toDate(),
                        moment(baseDate).subtract(1, "week").add(1, "minute").endOf("isoWeek").toDate(),
                    ],
                    placement: "left",
                },
                {
                    label: "Latest 2 weeks",
                    value: [
                        moment(baseDate).subtract(1, "week").add(1, "minute").subtract(1, "week").startOf("isoWeek").toDate(),
                        moment(baseDate).subtract(1, "week").add(1, "minute").endOf("isoWeek").toDate(),
                    ],
                    placement: "left",
                },
                {
                    label: "Latest month",
                    value: [
                        moment(baseDate).subtract(1, "week").add(1, "minute").subtract(1, "month").startOf("isoWeek").toDate(),
                        moment(baseDate).subtract(1, "week").add(1, "minute").endOf("isoWeek").toDate(),
                    ],
                    placement: "left",
                },
                {
                    label: "Latest quarter",
                    value: [
                        moment(baseDate).subtract(1, "week").add(1, "minute").subtract(1, "quarter").startOf("isoWeek").toDate(),
                        moment(baseDate).subtract(1, "week").add(1, "minute").endOf("isoWeek").toDate(),
                    ],
                    placement: "left",
                },
            ];
        }
    }, [latestCalculatedDates, formData.daypart]);

    const { sortedItems, requestSort, sortConfig } = useSortableData(musicTestDataMemo, { key: "combined.spins", direction: "descending" });

    const csvHeaders = isCombined
        ? [
              ...["Artist", "Title", "Release Year", "Combined Spins", "Combined Score", "Combined Burn", "Item ID"],
              ...musicTestData?.radio_ids.map(r => `${formData.stations.find(as => as.radio_id === r)?.radio_name} Score`),
          ]
        : ["Artist", "Title", "Release Year", "Spins", "Score", "Burn", "Delta %", "Familiarity", "Impressions", "Item ID"];
    const csvData = isCombined
        ? sortedItems?.map(x => [
              ...[
                  x.song.artist,
                  x.song.title,
                  x.song.release_year,
                  x.combined.spins,
                  x.combined.song_score?.toFixed(1),
                  x.combined.burn?.toFixed(1),
                  x.song.item_id,
              ],
              ...musicTestData?.radio_ids.map(r => x.individual.find(i => i.radio_id === r)?.song_score?.toFixed(1)),
          ]) ?? []
        : sortedItems?.map(x => [
              x.song.artist,
              x.song.title,
              x.song.release_year,
              x.combined.spins,
              x.combined.song_score?.toFixed(1),
              x.combined.burn?.toFixed(1),
              x.combined.weighted_delta?.toFixed(3),
              x.combined.familiarity_tier,
              x.combined.impressions,
              x.song.item_id,
          ]) ?? [];

    async function handleStationChange(value) {
        setFormData({ ...formData, stations: value, defaultStations: [] });
        stationsTypeahead.current.toggleMenu();
    }

    useEffect(() => {
        async function getLatestCalculatedDates(radioId) {
            const response = await axiosInstance.get(`music_test/${radioId}/latest_daypart_dates/?data_type=${formData.demo[0].data_type}`);
            setLatestCalculatedDates(response.data);
        }
        if (formData.stations.length) {
            getLatestCalculatedDates(formData.stations[0].radio_id);
        } else if (formData.defaultStations.length) {
            getLatestCalculatedDates(formData.defaultStations[0]);
        }
    }, [formData.stations, formData.defaultStations, formData.demo]);

    useEffect(() => {
        setFormData(current => ({
            ...current,
            daypart: formData.stations.length || formData.defaultStations.length ? current.defaultDaypart : "placeholder",
        }));
    }, [formData.stations, formData.defaultStations]);

    useEffect(() => {
        if (latestCalculatedDates.length && formData.daypart !== "placeholder") {
            const dt = latestCalculatedDates.find(x => x.daypart_id === formData.daypart).latest_date;
            setLatestCalculatedDate(dt);
            if (!formData.dateRange || moment(formData.dateRange[1]) > moment(dt).endOf("isoWeek")) {
                setFormData(current => ({
                    ...current,
                    dateRange: [
                        moment(dt).subtract(1, "week").add(1, "minute").startOf("isoWeek").toDate(),
                        moment(dt).subtract(1, "week").add(1, "minute").endOf("isoWeek").toDate(),
                    ],
                }));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData.daypart, latestCalculatedDates]);

    async function handleFormSubmit(event) {
        setDataLoading(true);
        event.preventDefault();
        if (formData.stations.length) {
            localStorage.setItem("musicTestStations", JSON.stringify(formData.stations.map(x => x.radio_id)));
            localStorage.setItem("musicTestDemo", JSON.stringify(formData.demo.map(x => x.radio_id)));
            localStorage.setItem("musicTestDaypart", JSON.stringify(formData.daypart));
        }
        const input = {
            radio_ids: formData.defaultStations.length ? formData.defaultStations : formData.stations.map(x => x.radio_id),
            start_date: moment(formData.dateRange[0]).format("YYYY-MM-DD"),
            end_date: moment(formData.dateRange[1]).format("YYYY-MM-DD"),
            daypart_id: formData.daypart,
            demo_id: 1,
        };
        setAdvancedOpen(false);
        try {
            const response = await axiosInstance.post("music_test/", input);
            const musicData = response.data;
            // probably temporary. Adds individual data object for non existant indiviudal data so path to it is consistent and sorting using path to property is possible
            if (musicData.radio_ids.length > 1) {
                for (const s of musicData.data) {
                    s.individual = musicData.radio_ids.map(r => s.individual.find(x => x.radio_id === r) ?? { radio_id: r });
                }
            }
            setBurnLocked(!(await radios).data.find(x => x.radio_id === musicData.radio_ids[0]).stream_settings.burn);
            setErrorCode(null);
            setMusicTestData(musicData);
        } catch (error) {
            setMusicTestData(null);
            setErrorCode(error?.response?.status ?? 500);
        }
        setDataLoading(false);
    }

    const virtualizer = useWindowVirtualizer({
        count: sortedItems?.length || 1,
        estimateSize: useCallback(() => 41, []),
        getItemKey: useCallback(i => sortedItems?.length && sortedItems[i].song.item_id, [sortedItems]),
        overscan: 20,
        scrollMargin: tableContainerRef.current?.offsetTop ?? 0,
    });

    return (
        <>
            <ModulePage>
                <ModulePage.Title>Music Testing</ModulePage.Title>
                <ModulePage.InputSelection
                    handleFormSubmit={handleFormSubmit}
                    disabled={
                        (!formData.defaultStations.length && !formData.stations.length) ||
                        !formData.demo.length ||
                        formData.daypart === "placeholder" ||
                        !formData.dateRange
                    }>
                    <InputSelection.Main>
                        <Suspense>
                            <Await resolve={radios}>
                                {resolvedRadios => (
                                    <Form.Group controlId="music-test-station" as={Col} className="mb-3 mb-xl-0 col-12 col-xl-3">
                                        <Form.Label>
                                            Station(s)
                                            <RATooltip>
                                                Select additional stations in the dropdown to see combined music test for songs played in your selected period.
                                                Results will be displayed in your chosen demographic.
                                            </RATooltip>
                                        </Form.Label>
                                        <Typeahead
                                            id="music-test-station-selection"
                                            ref={stationsTypeahead}
                                            options={resolvedRadios?.data
                                                .filter(radio => radio.stream_settings.song_deltas)
                                                .map(radio => ({
                                                    ...radio,
                                                }))}
                                            labelKey={"radio_name"}
                                            highlightOnlyResult
                                            multiple
                                            placeholder="Select station(s)..."
                                            clearButton={true}
                                            onChange={handleStationChange}
                                            selected={
                                                formData.defaultStations.length
                                                    ? resolvedRadios.data.filter(x => formData.defaultStations.includes(x.radio_id))
                                                    : formData.stations
                                            }
                                        />
                                    </Form.Group>
                                )}
                            </Await>
                        </Suspense>
                        <Form.Group controlId="music-test-demo" as={Col} className="mb-3 mb-xl-0 col-12 col-xl-3">
                            <Form.Label>Demographic</Form.Label>
                            <Typeahead
                                id="music-test-station-selection"
                                disabled
                                options={[]}
                                labelKey={"name"}
                                onChange={value => setFormData({ ...formData, demos: value })}
                                selected={formData.demo}
                            />
                        </Form.Group>

                        <Suspense>
                            <Await resolve={dayparts}>
                                {resolvedDayparts => (
                                    <Form.Group controlId="music-test-daypart" as={Col} className="mb-3 mb-xl-0 col-12 col-xl-3">
                                        <Form.Label>Daypart</Form.Label>
                                        <Form.Select
                                            value={formData.daypart}
                                            onChange={e =>
                                                setFormData({
                                                    ...formData,
                                                    daypart: Number(e.target.value),
                                                })
                                            }
                                            disabled={!formData.stations.length && !formData.defaultStations.length}>
                                            <option value={"placeholder"} disabled>
                                                {formData.demo.length ? "Select daypart..." : "Select station first..."}
                                            </option>
                                            {findRadioDayparts(resolvedDayparts.data, formData.stations[0]?.radio_id ?? formData.defaultStations[0])
                                                .sort((a, b) => a.daypart_id - b.daypart_id)
                                                .map(x => (
                                                    <option key={x.daypart_id} value={x.daypart_id}>
                                                        {formatDaypartText(x)}
                                                    </option>
                                                ))}
                                        </Form.Select>
                                    </Form.Group>
                                )}
                            </Await>
                        </Suspense>
                        <Form.Group controlId="music-test-daterange" as={Col} className="col-12 col-xl-3">
                            <Form.Label>
                                Time period
                                <RATooltip>
                                    Music Test is calculated on a weekly basis (Monday-Sunday). Use quick options to get the latest available data for this
                                    radio station.
                                </RATooltip>
                            </Form.Label>
                            <DateRangePicker
                                value={formData.dateRange}
                                onChange={value => setFormData({ ...formData, dateRange: value })}
                                placeholder="Select time period"
                                isoWeek
                                format={convertMomentToDatepickerFormat(moment.localeData(moment.locale()).longDateFormat("l"))}
                                disabled={formData.daypart === "placeholder"}
                                ranges={predefinedRanges}
                                shouldDisableDate={date => moment(moment(date).startOf("day").format()).isAfter(moment(latestCalculatedDate).local())}
                                block
                                hoverRange={"week"}
                                showOneCalendar
                            />
                        </Form.Group>
                    </InputSelection.Main>
                </ModulePage.InputSelection>

                <ModulePage.DataContent loading={dataLoading} errorCode={errorCode}>
                    <DataContent.Placeholder show={!musicTestData}>
                        <PageDesciption
                            title={"Music Testing"}
                            whatList={[
                                "Test every song you play, every week",
                                "Gauge listener reactions to the music you play, and see trends on each song for listener appea",
                                "Combine multiple stations you operate to create a 'format' test or mix audience types",
                            ]}
                            knowList={[
                                { text: "Song Score basics: if its green play it more, if its red play it less" },
                                {
                                    text: "Check out our ",
                                    url: "https://radioanalyzer.com/pdf/Music_Score_and_Burn.pdf",
                                    title: "Song Score and Burn",
                                    text2: " for a more detailed explanation of what the numbers mean, or just give us a call",
                                },
                                { text: "Data is collected weekly, but you can also display the trend month by month" },
                            ]}
                            relatedLinks={[
                                {
                                    title: "Christmas Music – What to Play, and When",
                                    url: "https://radioanalyzer.com/christmas-music/",
                                },
                                {
                                    title: "Radio Mythbusting – Do Ballads in the Morning Show Really Hurt Your Listening Curve?",
                                    url: "https://radioanalyzer.com/radio-mythbusting-ballads/",
                                },
                            ]}
                            videoSrc="https://www.youtube.com/embed/fJph2n7L1lg?si=FHktMcwP55sE0-1a"
                        />
                    </DataContent.Placeholder>
                    {musicTestData && (
                        <>
                            <DescriptionWithDownloadRow
                                csvHeaders={csvHeaders}
                                data={csvData}
                                filename={formData?.stations?.length === 1 ? `Music Testing ${formData?.stations[0]?.radio_name}` : "Combined Music Testing "}>
                                <Col xs={12} xl>
                                    <Stack direction="horizontal" className="flex-wrap column-gap-2">
                                        <Stack direction="horizontal" gap={2}>
                                            <span>Station(s):</span>
                                            <Suspense>
                                                <Await resolve={radios}>
                                                    {resolvedRadios => (
                                                        <span className="fw-bold">
                                                            {resolvedRadios.data
                                                                .filter(x => musicTestData?.radio_ids.includes(x.radio_id))
                                                                ?.map(x => x.radio_name)
                                                                ?.join(", ")}
                                                        </span>
                                                    )}
                                                </Await>
                                            </Suspense>
                                        </Stack>

                                        <div className="vr d-none d-xl-inline-block"></div>
                                        <Stack direction="horizontal" gap={2}>
                                            <span>Time period:</span>
                                            <span className="fw-bold">
                                                {moment(musicTestData?.start_date).format("L")} - {moment(musicTestData?.end_date).format("L")}
                                            </span>
                                        </Stack>
                                        <div className="vr d-none d-xl-inline-block"></div>
                                        <Stack direction="horizontal" gap={2}>
                                            <span>Perspective:</span>
                                            <Suspense>
                                                <Await resolve={dayparts}>
                                                    {resolvedDayparts => (
                                                        <span className="fw-bold">
                                                            {formatDaypartText(
                                                                findRadioDayparts(resolvedDayparts.data, musicTestData?.radio_ids[0]).find(
                                                                    x => x.daypart_id === musicTestData?.daypart_id
                                                                )
                                                            )}
                                                        </span>
                                                    )}
                                                </Await>
                                            </Suspense>
                                        </Stack>
                                        {(filterData.minSpins > 0 || filterData.maxSpins > 0) && (
                                            <>
                                                <div className="vr d-none d-xl-inline-block"></div>
                                                <Stack direction="horizontal" gap={1}>
                                                    <span>Spins: </span>
                                                    <span className="fw-bold">{`${filterData.minSpins} - ${filterData.maxSpins || "∞"}`}</span>
                                                </Stack>
                                            </>
                                        )}
                                    </Stack>
                                </Col>
                            </DescriptionWithDownloadRow>

                            <Stack direction="horizontal" gap={3} className=" mb-2 flex-wrap-reverse row-gap-1">
                                <Col xs={12} xl xxl={5}>
                                    <InputGroup>
                                        <InputGroup.Text className="input-icon">
                                            <Search />
                                        </InputGroup.Text>
                                        <Form.Control
                                            value={filterData.searchString}
                                            onChange={e => setFilterData({ ...filterData, searchString: e.target.value })}
                                            placeholder="search by artist, title or release date"
                                        />
                                    </InputGroup>
                                </Col>
                                <Stack direction="horizontal" gap={1} className=" ms-xl-auto">
                                    <span className="me-1">Hide deltas(%):</span>
                                    <Form.Switch
                                        id="music-test-hide-deltas"
                                        inline
                                        checked={filterData.hideDeltas}
                                        onChange={e =>
                                            setFilterData({
                                                ...filterData,
                                                hideDeltas: e.target.checked,
                                            })
                                        }
                                    />
                                </Stack>
                                <Stack direction="horizontal" gap={1}>
                                    <span className="me-1">Hide Invalid Deltas:</span>
                                    <Form.Switch
                                        id="music-test-hide-invalid"
                                        inline
                                        checked={filterData.hideInvalid}
                                        onChange={e =>
                                            setFilterData({
                                                ...filterData,
                                                hideInvalid: e.target.checked,
                                            })
                                        }
                                    />
                                </Stack>
                                <Stack direction="horizontal" gap={1}>
                                    <span className="me-1">Spins:</span>
                                    <MinMaxSpinsPicker filterData={filterData} setFilterData={setFilterData} name={moduleName} inputOnly />
                                </Stack>
                            </Stack>

                            <div className=" overflow-x-auto">
                                <MusicTestHeader
                                    isCombined={isCombined}
                                    burnLocked={burnLocked}
                                    hideDeltas={filterData.hideDeltas}
                                    radioIds={musicTestData.radio_ids}
                                    sortConfig={sortConfig}
                                    requestSort={requestSort}
                                />
                                <VirtualizedWindowContainer
                                    className="music-test-mw table-container-hover"
                                    containerRef={tableContainerRef}
                                    virtualizer={virtualizer}>
                                    {virtualizer.getVirtualItems().map(virtualRow => {
                                        const item = sortedItems[virtualRow.index];
                                        if (!item) return <EmptyTableRow key={virtualRow.key} text="No songs" />;
                                        return (
                                            <MusicTestRow
                                                key={virtualRow.key}
                                                isCombined={isCombined}
                                                burnLocked={burnLocked}
                                                hideDeltas={filterData.hideDeltas}
                                                item={item}
                                                radioIds={musicTestData.radio_ids}
                                                virtualizer={virtualizer}
                                                virtualRow={virtualRow}
                                                setTrendSong={setTrendSong}
                                                hoveredRowIndex={hoveredRowIndex}
                                                setHoveredRowIndex={setHoveredRowIndex}
                                            />
                                        );
                                    })}
                                </VirtualizedWindowContainer>
                            </div>
                        </>
                    )}
                    <MusicTestTrendModalComponent radioId={musicTestData?.radio_ids[0]} dataType={formData.demo[0]?.data_type} daypartId={formData.daypart} />
                </ModulePage.DataContent>
            </ModulePage>
        </>
    );
};

export default MusicTesting;
