import Button from "react-bootstrap/Button";
import {Modal} from "react-bootstrap";

export default function ConfirmPrompt({
                                          handleConfirm, handleClose, show,
                                          bodyText = "Are you sure you wanna do this?",
                                          confirmText = "Yes, delete",
                                          cancelText = "Nope",
                                          titleText = "Are you sure?"
                                      }) {
    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton closeVariant={"white"}>
                    <Modal.Title>{titleText}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{bodyText}</Modal.Body>
                <Modal.Footer>
             
                    <Button variant="danger" onClick={handleConfirm}>
                        {confirmText}
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        {cancelText}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}