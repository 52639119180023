import { Button, Col, Container, Row } from "react-bootstrap";
import Employees from "../../components/Employees";
import { useNavigate } from "react-router-dom";

export default function Error() {
    const navigate = useNavigate();

    return (
        <Container className="text-center align-self-center">
            <Row>
                <Col>
                    <h1 className="h1-lg">Oh no, something went wrong!</h1>
                    <Button onClick={() => navigate(-1)} className="btn btn-ra-dark-blue px-5 mb-3">
                        Go Back
                    </Button>
                    <p>
                        If the issue persist, please contact{" "}
                        <a
                            className="fw-bold color-ra-dark-blue link-underline-dark link-underline-opacity-0 link-underline-opacity-75-hover"
                            href={`mailto:support@radioanalyzer.com?subject=${encodeURIComponent("Page Not Found")}`}>
                            support@radioanalyzer.com
                        </a>
                    </p>
                    <Employees />
                </Col>
            </Row>
        </Container>
    );
}
