import { useMemo, useState } from "react";

const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = useState(config);

    function getNestedProperty(obj, key) {
        // if path is not a string or array of string
        if (key === "" || key.length === 0) return undefined;

        // if path is an array, concatenate it and form a string
        // to handle a single case of string
        if (Array.isArray(key)) key = key.join(".");

        // filter out the brackets and dot
        const exactPath = key.split(/[.[\]]/).filter(Boolean);

        // get the value of the path in the sequence
        let value = obj;
        for (const path of exactPath) {
            if (value === undefined) {
                return null; // Return undefined if any part of the path is undefined.
            }
            value = value[path];
        }
        // if not found return undefined
        return value ? value : null;
    }

    const sortedItems = useMemo(() => {
        if (!items) return null;
        let sortableItems = [...items];
        if (sortConfig !== null) {
            sortableItems.sort((a, b) => {
                if (sortConfig.customOrder) {
                    const indexA = sortConfig.customOrder.indexOf(getNestedProperty(a, sortConfig.key));
                    const indexB = sortConfig.customOrder.indexOf(getNestedProperty(b, sortConfig.key));

                    if (indexA !== indexB) {
                        return sortConfig.direction === "ascending" ? indexA - indexB : indexB - indexA;
                    }
                } else {
                    if (getNestedProperty(a, sortConfig.key) < getNestedProperty(b, sortConfig.key)) {
                        return sortConfig.direction === "ascending" ? -1 : 1;
                    }
                    if (getNestedProperty(a, sortConfig.key) > getNestedProperty(b, sortConfig.key)) {
                        return sortConfig.direction === "ascending" ? 1 : -1;
                    }
                }
                return 0;
            });
        }
        return sortableItems;
    }, [items, sortConfig]);

    const requestSort = (key, direction = "descending", customOrder = null) => {
        let effectiveDirection = direction;
        if (sortConfig && sortConfig.key === key && sortConfig.direction === direction) {
            effectiveDirection = direction === "ascending" ? "descending" : "ascending";
        }
        setSortConfig({ key, direction: effectiveDirection, customOrder });
    };

    return { sortedItems, requestSort, sortConfig };
};

export default useSortableData;
