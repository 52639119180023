import { Button } from "react-bootstrap";
import { FiletypePng } from "react-bootstrap-icons";
import { useScreenshot, createFileName } from "use-react-screenshot";

export default function ScreenshotButton({ container, fileName, disabled = false }) {
    // eslint-disable-next-line no-unused-vars
    const [image, takeScreenshot] = useScreenshot({
        type: "image/png",
        quality: 1.0,
    });
    const download = (image, { name = fileName, extension = "png" } = {}) => {
        const a = document.createElement("a");
        a.href = image;
        a.download = createFileName(extension, name);
        a.click();
    };
    const downloadScreenshot = () => takeScreenshot(container.current).then(download);

    return (
        <Button variant={"contained"} onClick={downloadScreenshot} disabled={disabled} className={`p-0 btn-download border-0`}>
            <FiletypePng width={30} height={30} />
        </Button>
    );
}
