import { Col, Container, Row, Stack } from "react-bootstrap";
import { CaretDownFill, CaretUpFill, LockFill } from "react-bootstrap-icons";
import RATooltip from "../RATooltip";
import { Suspense } from "react";
import { Await, useRouteLoaderData } from "react-router-dom";
import SongScoreTooltip from "./SongScoreTooltip";

export default function MusicTestHeader({ sortConfig, requestSort, hideDeltas, isCombined, burnLocked, radioIds }) {
    return (
        <Container fluid className="music-test-mw">
            <Row className="bg-ra-dark-blue text-white fw-bold py-1 rounded-2 fs-8-xs table-header user-select-none h-100">
                <Col xs={3} xl={4} xxl={5} className=" align-content-center">
                    <Row className=" h-100">
                        <Col onClick={() => requestSort("song.artist")} className=" align-content-center">
                            <Stack direction="horizontal">
                                <span>Artist</span>
                                <span className="col-1">
                                    {sortConfig?.key === "song.artist" && (sortConfig.direction === "descending" ? <CaretDownFill /> : <CaretUpFill />)}
                                </span>
                            </Stack>
                        </Col>
                        <Col onClick={() => requestSort("song.title")} className=" align-content-center">
                            <Stack direction="horizontal">
                                <span>Title</span>
                                <span className="col-1">
                                    {sortConfig?.key === "song.title" && (sortConfig.direction === "descending" ? <CaretDownFill /> : <CaretUpFill />)}
                                </span>
                            </Stack>
                        </Col>
                    </Row>
                </Col>
                <Col>{isCombined ? <CombinedHeader /> : <SingleHeader />}</Col>
            </Row>
        </Container>
    );

    function SingleHeader() {
        return (
            <Row className={`music-test-short-cols ${hideDeltas ? "" : "with-deltas"} h-100`}>
                <Col onClick={() => requestSort("song.release_year")} className=" align-content-center">
                    <Stack direction="horizontal" className="justify-content-center text-center">
                        <span className="offset-1">Release Year</span>
                        <span className="col-1">
                            {sortConfig?.key === "song.release_year" && (sortConfig.direction === "descending" ? <CaretDownFill /> : <CaretUpFill />)}
                        </span>
                    </Stack>
                </Col>
                <Col onClick={() => requestSort("combined.spins")} className=" align-content-center">
                    <Stack direction="horizontal" className="justify-content-center text-center">
                        <span className="offset-1">Spins</span>
                        <span className="col-1">
                            {sortConfig?.key === "combined.spins" && (sortConfig.direction === "descending" ? <CaretDownFill /> : <CaretUpFill />)}
                        </span>
                    </Stack>
                </Col>
                <Col onClick={() => requestSort("combined.song_score")} className=" align-content-center">
                    <Stack direction="horizontal" className="justify-content-center text-center">
                        <span className=" d-flex flex-wrap-reverse column-gap-1 justify-content-center offset-1 align-items-center">
                            Score
                            <SongScoreTooltip />
                        </span>
                        <span className="col-1">
                            {sortConfig?.key === "combined.song_score" && (sortConfig.direction === "descending" ? <CaretDownFill /> : <CaretUpFill />)}
                        </span>
                    </Stack>
                </Col>
                <Col onClick={() => (burnLocked ? null : requestSort("combined.burn"))} className=" align-content-center text-center">
                    <Stack direction="horizontal" className="justify-content-center">
                        <span className=" d-flex flex-wrap-reverse justify-content-center offset-1 column-gap-1 align-items-center">
                            Burn
                            {burnLocked && <RATooltip tooltipElement={<LockFill />}>Unlock the Burn feature - contact us at info@radioanalyzer.com</RATooltip>}
                            <RATooltip>
                                Burn measures how overplayed a song is for your audience. High burn is an indicator to play it less often - this could be hits
                                that need a break, or a new song that is being pushed too early.
                            </RATooltip>
                        </span>
                        <span className="col-1">
                            {sortConfig?.key === "combined.burn" && (sortConfig.direction === "descending" ? <CaretDownFill /> : <CaretUpFill />)}
                        </span>
                    </Stack>
                </Col>
                {!hideDeltas && (
                    <Col onClick={() => requestSort("combined.weighted_delta")} className=" align-content-center">
                        <Stack direction="horizontal" className="justify-content-center text-center">
                            <span className="offset-1">Delta %</span>
                            <span className="col-1">
                                {sortConfig?.key === "combined.weighted_delta" && (sortConfig.direction === "descending" ? <CaretDownFill /> : <CaretUpFill />)}
                            </span>
                        </Stack>
                    </Col>
                )}
                <Col onClick={() => requestSort("combined.familiarity")} className=" align-content-center">
                    <Stack direction="horizontal" className="justify-content-center">
                        <span className=" d-flex flex-wrap-reverse justify-content-center offset-1 column-gap-1 align-items-center">
                            Familiarity
                            <RATooltip>
                                Songs with a high familiarity are heard most often by your listeners across all stations, and song with a low familiarity are
                                only heard rarely.
                            </RATooltip>
                        </span>
                        <span className="col-1">
                            {sortConfig?.key === "combined.familiarity" && (sortConfig.direction === "ascending" ? <CaretDownFill /> : <CaretUpFill />)}
                        </span>
                    </Stack>
                </Col>
                <Col onClick={() => requestSort("combined.impressions")} className=" align-content-center">
                    <Stack direction="horizontal" className="justify-content-center">
                        <span className="offset-1">Impressions</span>
                        <span className="col-1">
                            {sortConfig?.key === "combined.impressions" && (sortConfig.direction === "descending" ? <CaretDownFill /> : <CaretUpFill />)}
                        </span>
                    </Stack>
                </Col>
                <Col className="text-center align-content-center">Trend</Col>
            </Row>
        );
    }

    function CombinedHeader() {
        const width = 100 / (4 + radioIds.length);
        const colsStyle = { width: `${width}%`, maxWidth: `${width}%`, flex: `0 0 ${width}%` };
        const { radios } = useRouteLoaderData("root");

        return (
            <Row>
                <Col style={colsStyle} onClick={() => requestSort("song.release_year")} className=" align-content-center">
                    <Stack direction="horizontal" className="justify-content-center text-center">
                        <span className="offset-1">Release Year</span>
                        <span className="col-1">
                            {sortConfig?.key === "song.release_year" && (sortConfig.direction === "descending" ? <CaretDownFill /> : <CaretUpFill />)}
                        </span>
                    </Stack>
                </Col>
                <Col style={colsStyle} onClick={() => requestSort("combined.spins")} className=" align-content-center">
                    <Stack direction="horizontal" className="justify-content-center text-center">
                        <span className="offset-1">Combined Spins</span>
                        <span className="col-1">
                            {sortConfig?.key === "combined.spins" && (sortConfig.direction === "descending" ? <CaretDownFill /> : <CaretUpFill />)}
                        </span>
                    </Stack>
                </Col>
                <Col style={colsStyle} onClick={() => requestSort("combined.song_score")} className=" align-content-center">
                    <Stack direction="horizontal" className="justify-content-center text-center">
                        <span className=" d-flex flex-wrap-reverse column-gap-1 justify-content-center offset-1 align-items-center">
                            Combined Score
                            <SongScoreTooltip />
                        </span>
                        <span className="col-1">
                            {sortConfig?.key === "combined.song_score" && (sortConfig.direction === "descending" ? <CaretDownFill /> : <CaretUpFill />)}
                        </span>
                    </Stack>
                </Col>
                <Col style={colsStyle} onClick={() => (burnLocked ? null : requestSort("combined.burn"))} className=" align-content-center text-center">
                    <Stack direction="horizontal" className="justify-content-center">
                        <span className=" d-flex flex-wrap-reverse justify-content-center offset-1 column-gap-1 align-items-center">
                            Combined Burn
                            {burnLocked && <RATooltip tooltipElement={<LockFill />}>Unlock the Burn feature - contact us at info@radioanalyzer.com</RATooltip>}
                            <RATooltip>
                                Burn measures how overplayed a song is for your audience. High burn is an indicator to play it less often - this could be hits
                                that need a break, or a new song that is being pushed too early.
                            </RATooltip>
                        </span>
                        <span className="col-1">
                            {sortConfig?.key === "combined.burn" && (sortConfig.direction === "descending" ? <CaretDownFill /> : <CaretUpFill />)}
                        </span>
                    </Stack>
                </Col>
                {radioIds.map((x, i) => (
                    <Col style={colsStyle} key={i} onClick={() => requestSort(`individual[${i}].song_score`)} className=" align-content-center">
                        <Suspense>
                            <Await resolve={radios}>
                                {resolvedRadios => (
                                    <Stack direction="horizontal" className=" justify-content-center text-center">
                                        <span className="offset-1">{resolvedRadios.data.find(r => r.radio_id === x)?.radio_name}</span>
                                        <span className="col-1">
                                            {sortConfig?.key === `individual[${i}].song_score` &&
                                                (sortConfig.direction === "descending" ? <CaretDownFill /> : <CaretUpFill />)}
                                        </span>
                                    </Stack>
                                )}
                            </Await>
                        </Suspense>
                    </Col>
                ))}
            </Row>
        );
    }
}
