import React from "react";
import { Col, Container, Row } from "react-bootstrap";

export default function PageDesciption({ title, whatList, knowList, relatedLinks = [], videoSrc, className = "" }) {
    return (
        <Container fluid className={`px-0 ${className}`}>
            <Row>
                <Col xs={12} xl={8}>
                    <h6 className="fw-bold list-unstyled">What can you do with {title}?</h6>
                    <ul>
                        {whatList.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                    <h6 className="fw-bold">Nice to know</h6>
                    <ul>
                        {knowList.map((item, index) => (
                            <li key={index}>
                                {item.url ? (
                                    <>
                                        {item.text}
                                        <a href={item.url} target="_blank" rel="noopener noreferrer" className="text-dark fw-bold">
                                            {item.title}
                                        </a>
                                        {item.text2}
                                    </>
                                ) : (
                                    item.text
                                )}
                            </li>
                        ))}
                    </ul>
                    {relatedLinks ? <li className="fw-bold list-unstyled">Related reading:</li> : ""}
                    {relatedLinks.map((link, index) => (
                        <li key={index} className="list-unstyled">
                            <a className="text-dark text-decoration-none fst-italic p-2" href={link.url} target="_blank" rel="noopener noreferrer">
                                {link.title}
                            </a>
                        </li>
                    ))}
                    <p className="mt-2">
                        If you need further assistance in using {title}, please contact{" "}
                        <a
                            className="text-dark fw-bold "
                            href={`mailto:training@radioanalyzer.com?subject=Training%20for%20${encodeURIComponent(title)}`}
                            target=" blank">
                            training@radioanalyzer.com
                        </a>
                    </p>
                </Col>
                <Col xs={12} xl={4}>
                    <iframe
                        width="100%"
                        height="100%"
                        src={videoSrc}
                        title={`How to use ${title}`}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>
                </Col>
            </Row>
        </Container>
    );
}
