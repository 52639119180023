import { useRef, useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { LockFill } from "react-bootstrap-icons";
import { useNavigate, useParams } from "react-router-dom";
import { axiosInstance } from "../../auth/Auth";
import { toast } from "react-toastify";

export default function ResetPassword() {
    const formRef = useRef();
    let { token } = useParams();
    const navigate = useNavigate();

    const [resetting, setResetting] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");

    const lengthValid = password.length >= 8;
    const uppercaseValid = /[A-Z]/.test(password);
    const lowercaseValid = /[a-z]/.test(password);
    const digitValid = /\d/.test(password);
    const passwordsMatch = password === password2;
    const isValid = lengthValid && uppercaseValid && lowercaseValid;

    const handleSubmit = async event => {
        event.preventDefault();
        setResetting(true);
        try {
            const response = await axiosInstance.put("/reset_password/", {
                passwords: { new_password_1: password, new_password_2: password2 },
                token: token,
            });
            toast.success("Your password was reset successfully");
            navigate("/login", { replace: true });
        } catch (error) {
            if (error?.response?.status === 400 || error?.response?.status === 401) setErrorMessage(error.response.data.detail);
            else setErrorMessage("Unexpected error occured. Please try again later or contact us at tech@radioanalyzer.com");
        }
        setResetting(false);
    };

    return (
        <>
            <h1 className="text-light fw-bold mb-3 mt-xl-0 lh-sm d-none d-xl-inline-block fs-2">Set new password</h1>
            <Form onSubmit={handleSubmit} ref={formRef} className=" mb-auto mb-xl-0" noValidate>
                <InputGroup className="mb-2">
                    <InputGroup.Text className="text-blue bg-beige color-ra-dark-blue fs-4 p-2">
                        <LockFill />
                    </InputGroup.Text>
                    <Form.Control
                        className={`rounded-end`}
                        size="lg"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        required
                        type="password"
                        name="password"
                        placeholder="New password"
                        isValid={isValid}
                    />
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Text className="text-blue bg-beige color-ra-dark-blue fs-4 p-2">
                        <LockFill />
                    </InputGroup.Text>
                    <Form.Control
                        className="rounded-end"
                        size="lg"
                        value={password2}
                        onChange={e => setPassword2(e.target.value)}
                        required
                        type="password"
                        name="password2"
                        placeholder="Confirm new password"
                        isValid={password && passwordsMatch}
                    />
                </InputGroup>
                <ul className=" mb-4 text-light" data-bs-theme={"dark"}>
                    <li className={lengthValid ? "text-success-emphasis" : ""}>Must contain at least 8 characters</li>
                    <li className={uppercaseValid ? "text-success-emphasis" : ""}>Must contain at least 1 uppercase letter</li>
                    <li className={lowercaseValid ? "text-success-emphasis" : ""}>Must contain at least 1 lowercase letter</li>
                    <li className={digitValid ? "text-success-emphasis" : ""}>Must contain at least 1 digit</li>
                    <li className={password && passwordsMatch ? "text-success-emphasis" : ""}>Passwords must match</li>
                </ul>
                <Row className=" row-cols-1">
                    <Col>
                        <Button
                            className="btn-ra-yellow text-uppercase fw-bold w-100 fs-5"
                            size="sm"
                            disabled={resetting || !isValid || !passwordsMatch}
                            type={"submit"}>
                            Reset Password
                        </Button>
                    </Col>
                </Row>
                <div className="position-relative mt-2" data-bs-theme="dark">
                    {errorMessage && <label className="text-warning-emphasis position-absolute">{errorMessage}</label>}
                </div>
            </Form>
        </>
    );
}
