import React, { Suspense } from "react";
import { Form } from "react-bootstrap";
import { Await, useLoaderData } from "react-router-dom";

export default function SongCategoryPicker({ item, handleChange }) {
    const { songCategories } = useLoaderData();

    return (
        <Suspense fallback={<Fallback />}>
            <Await resolve={songCategories}>
                {resolvedCategories => (
                    <Form.Select
                        value={item.song_category_move}
                        onClick={e => e.stopPropagation()}
                        onChange={e => handleChange(e.target.value, item.song.item_id)}>
                        {resolvedCategories.data.map(x => (
                            <option key={x.code} value={x.code}>
                                {x.name}
                            </option>
                        ))}
                    </Form.Select>
                )}
            </Await>
        </Suspense>
    );
}

function Fallback() {
    return (
        <Form.Select value={"placeholder"} disabled>
            <option value={"placeholder"}>Loading categories...</option>
        </Form.Select>
    );
}
