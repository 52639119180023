import { ReactComponent as LogoTitle } from "../../assets/Logo_in_svg.svg";
import "../../components/Navigation/styles.css";
import { Await, NavLink, useRouteLoaderData } from "react-router-dom";
import { useAuth } from "../../auth/Auth";
import { BoxArrowLeft, ClipboardData, Gear, List, MusicNoteBeamed, MusicNoteList, Search } from "react-bootstrap-icons";
import { Suspense, useEffect, useRef, useState } from "react";
import { Button, Collapse, Container, Nav, Navbar, OverlayTrigger, Stack, Tooltip } from "react-bootstrap";

const SideNavigation = () => {
    const { userInfo } = useRouteLoaderData("root");
    const { onLogout } = useAuth();
    const [open, setOpen] = useState(false);

    const sidebarRef = useRef(null);
    const navbarRef = useRef(null);
    const sidebarToggleRef = useRef(null);
    const navbarToggleRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = event => {
            // Close when clicked outside
            if (open && sidebarRef.current && !sidebarRef.current.contains(event.target) && !navbarRef.current.contains(event.target)) setOpen(false);
            // Close when clicked on a link inside
            if (
                open &&
                sidebarRef.current &&
                sidebarRef.current !== event.target &&
                sidebarToggleRef.current &&
                !sidebarToggleRef.current.contains(event.target) &&
                navbarToggleRef.current &&
                !navbarToggleRef.current.contains(event.target)
            ) {
                setOpen(false);
            }
        };
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, [open]);

    const renderTooltip = text => (
        <Tooltip className={`${open && "d-none"} nav-tooltip opacity-100`} id="nav-tooltip">
            {text}
        </Tooltip>
    );

    return (
        <>
            <Navbar fixed="top" variant="dark" expand="xl" className="d-xl-none py-1" expanded={open} ref={navbarRef} onToggle={v => setOpen(v)}>
                <Container fluid className=" px-3">
                    <Navbar.Brand as={NavLink} href={"dashboard"} to={"dashboard"} className=" p-0">
                        <LogoTitle height={20} width={undefined} />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" ref={navbarToggleRef} />
                </Container>
                <Navbar.Collapse id="responsive-navbar-nav" className="mt-1 px-0">
                    <Nav>
                        <Nav.Link as={NavLink} href={"music_test"} to={"music_test"} className={"p-1 pe-2"}>
                            <Stack direction="horizontal" gap={3} className="p-2">
                                <MusicNoteBeamed height={35} width={35} />
                                <span className="text-nowrap">Music Test</span>
                            </Stack>
                        </Nav.Link>
                        <Nav.Link as={NavLink} href={"playlist_autopilot"} to={"playlist_autopilot"} className={"p-1 pe-2"}>
                            <Stack direction="horizontal" gap={3} className="p-2">
                                <MusicNoteList height={35} width={35} />
                                <span className="text-nowrap">Playlist Autopilot</span>
                            </Stack>
                        </Nav.Link>
                        <Nav.Link as={NavLink} href={"song_search"} to={"song_search"} className={"p-1 pe-2"}>
                            <Stack direction="horizontal" gap={3} className="p-2">
                                <Search className="p-1" height={35} width={35} />
                                <span className="text-nowrap">Song Search</span>
                            </Stack>
                        </Nav.Link>
                        <Nav.Link as={NavLink} href={"music_test_reports"} to={"music_test_reports"} className={"nav-link p-1 pe-2"}>
                            <Stack direction="horizontal" gap={3} className="p-2">
                                <ClipboardData className="p-1" height={35} width={35} />
                                <span className="text-nowrap">Music Test Reports</span>
                            </Stack>
                        </Nav.Link>
                        <hr className=" my-1" />
                        <Nav.Link as={NavLink} href={"settings"} to={"settings"} className={"p-1 pe-2"}>
                            <Stack direction="horizontal" gap={3} className="p-2">
                                <Gear className="p-1" height={35} width={35} />
                                <span className="text-nowrap">Settings</span>
                            </Stack>
                        </Nav.Link>
                        <Button className={"nav-link p-1 pe-2"} variant="contained" onClick={onLogout}>
                            <Stack direction="horizontal" gap={3} className="p-2">
                                <BoxArrowLeft className="p-1" height={35} width={35} />
                                <Suspense>
                                    <Await resolve={userInfo}>{resolvedUser => <span className="text-nowrap">Logout {resolvedUser.data.username}</span>}</Await>
                                </Suspense>
                            </Stack>
                        </Button>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <Collapse in={open} dimension={"width"}>
                <div id="sidebar-collapse" className=" z-3 position-fixed d-none d-xl-flex">
                    <div className=" sidebar" ref={sidebarRef}>
                        <Navbar.Brand as={NavLink} to={"dashboard"} className=" mt-4 p-1">
                            <LogoTitle height={open ? 40 : 52} width={undefined} className="p-2" />
                        </Navbar.Brand>
                        <hr />
                        <ul className="nav nav-pills flex-column mb-auto nav-pills-ra mt-3">
                            <li className="nav-item">
                                <NavLink to={"music_test"} className={"nav-link p-1 pe-2"}>
                                    <Stack direction="horizontal" gap={3} className="p-2">
                                        <OverlayTrigger placement="right" overlay={renderTooltip("Music Test")}>
                                            <MusicNoteBeamed height={35} width={35} />
                                        </OverlayTrigger>
                                        <span className="text-nowrap">Music Test</span>
                                    </Stack>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to={"playlist_autopilot"} className={"nav-link p-1 pe-2"}>
                                    <Stack direction="horizontal" gap={3} className="p-2">
                                        <OverlayTrigger placement="right" overlay={renderTooltip("Playlist Autopilot")}>
                                            <MusicNoteList height={35} width={35} />
                                        </OverlayTrigger>
                                        <span className="text-nowrap">Playlist Autopilot</span>
                                    </Stack>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to={"song_search"} className={"nav-link p-1 pe-2"}>
                                    <Stack direction="horizontal" gap={3} className="p-2">
                                        <OverlayTrigger placement="right" overlay={renderTooltip("Song Search")}>
                                            <Search className="p-1" height={35} width={35} />
                                        </OverlayTrigger>
                                        <span className="text-nowrap">Song Search</span>
                                    </Stack>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to={"music_test_reports"} className={"nav-link p-1 pe-2"}>
                                    <Stack direction="horizontal" gap={3} className="p-2">
                                        <OverlayTrigger placement="right" overlay={renderTooltip("Music Test Reports")}>
                                            <ClipboardData className="p-1" height={35} width={35} />
                                        </OverlayTrigger>
                                        <span className="text-nowrap">Music Test Reports</span>
                                    </Stack>
                                </NavLink>
                            </li>
                        </ul>
                        <hr />
                        <ul className="nav nav-pills flex-column nav-pills-ra">
                            <li className="nav-item w-100">
                                <Button
                                    ref={sidebarToggleRef}
                                    variant="contained"
                                    className=" sidebar-toggle border-0  p-1 pe-2 w-100"
                                    onClick={() => setOpen(!open)}
                                    aria-controls="sidebar-collapse"
                                    aria-expanded={open}>
                                    <Stack direction="horizontal" gap={3} className="p-2">
                                        <OverlayTrigger placement="right" overlay={renderTooltip("Expand")}>
                                            <List height={35} width={35} />
                                        </OverlayTrigger>
                                        <span className="text-nowrap">Collapse</span>
                                    </Stack>
                                </Button>
                            </li>
                            <li className="nav-item w-100">
                                <NavLink to={"settings"} className={"nav-link p-1 pe-2 w-100"}>
                                    <Stack direction="horizontal" gap={3} className="p-2">
                                        <OverlayTrigger placement="right" overlay={renderTooltip("Settings")}>
                                            <Gear className="p-1" height={35} width={35} />
                                        </OverlayTrigger>
                                        <span className="text-nowrap">Settings</span>
                                    </Stack>
                                </NavLink>
                            </li>
                            <li className="nav-item w-100">
                                <Button className={"nav-link p-1 pe-2 w-100"} variant="contained" onClick={onLogout}>
                                    <Stack direction="horizontal" gap={3} className="p-2">
                                        <OverlayTrigger placement="right" overlay={renderTooltip("Logout")}>
                                            <BoxArrowLeft className="p-1" height={35} width={35} />
                                        </OverlayTrigger>
                                        <Suspense>
                                            <Await resolve={userInfo}>
                                                {resolvedUser => <span className="text-nowrap text-truncate">Logout {resolvedUser.data.username}</span>}
                                            </Await>
                                        </Suspense>
                                    </Stack>
                                </Button>
                            </li>
                        </ul>
                    </div>
                </div>
            </Collapse>
        </>
    );
};

export default SideNavigation;
