import { Button, Col, Row } from "react-bootstrap";
import { getSongDeltaColorCss, getSongScoreColorCss, toAutoDecimals } from "../HelperFunctions";
import { BarChartLineFill, LockFill } from "react-bootstrap-icons";

export default function MusicTestRow({
    isCombined,
    burnLocked,
    hideDeltas,
    radioIds,
    item,
    virtualizer,
    virtualRow,
    setTrendSong,
    hoveredRowIndex,
    setHoveredRowIndex,
}) {
    return (
        <Row
            data-index={virtualRow?.index}
            ref={virtualizer?.measureElement}
            style={{ transform: `translateY(${virtualRow?.start - virtualizer?.options.scrollMargin}px)` }}
            className={`py-1 border-bottom w-100 position-absolute ${hoveredRowIndex === virtualRow?.index ? "active" : ""}`}
            onMouseEnter={() => setHoveredRowIndex(virtualRow?.index)}
            onMouseLeave={() => setHoveredRowIndex(null)}>
            <Col xs={3} xl={4} xxl={5} className=" align-content-center">
                <Row className=" align-items-center">
                    <Col>{item.song.artist}</Col>
                    <Col>{item.song.title}</Col>
                </Row>
            </Col>
            <Col className=" align-content-center">{isCombined ? <CombinedRow /> : <SingleRow />}</Col>
        </Row>
    );

    function SingleRow() {
        return (
            <Row className={`music-test-short-cols ${hideDeltas ? "" : "with-deltas"} align-items-center`}>
                <Col className="text-center">{item.song.release_year ?? "-"}</Col>
                <Col className="text-center">{item.combined.spins}</Col>
                <Col className=" d-flex justify-content-center">
                    <div className={`score ${getSongScoreColorCss(toAutoDecimals(item.combined.song_score, 1), true)}`}>
                        {toAutoDecimals(item.combined.song_score, 1)}
                    </div>
                </Col>
                <Col className="text-center fw-bold">{burnLocked ? <LockFill /> : toAutoDecimals(item.combined.burn, 1) ?? "-"}</Col>
                {!hideDeltas && (
                    <Col xs className={`text-center fw-bold ${getSongDeltaColorCss(item.combined.weighted_delta)}`}>
                        {toAutoDecimals(item.combined.weighted_delta, 2)}
                    </Col>
                )}
                <Col className="text-center text-capitalize">{item.combined.familiarity_tier}</Col>
                <Col className="text-center">{item.combined.impressions}</Col>
                <Col className="text-center">
                    <Button
                        className=" btn-ra-yellow py-1 d-flex align-items-center column-gap-1 mx-auto"
                        size="sm"
                        onClick={e => {
                            e.stopPropagation();
                            setTrendSong(item.song);
                        }}>
                        <BarChartLineFill /> Trend
                    </Button>
                </Col>
            </Row>
        );
    }

    function CombinedRow() {
        const width = 100 / (4 + radioIds.length);
        const colsStyle = { width: `${width}%`, maxWidth: `${width}%`, flex: `0 0 ${width}%` };
        return (
            <Row className="align-items-center">
                <Col style={colsStyle} className="text-center">
                    {item.song.release_year ?? "-"}
                </Col>
                <Col style={colsStyle} className="text-center">
                    {item.combined.spins}
                </Col>
                <Col style={colsStyle} className=" d-flex justify-content-center">
                    <div className={`score ${getSongScoreColorCss(toAutoDecimals(item.combined.song_score, 1), true)}`}>
                        {toAutoDecimals(item.combined.song_score, 1)}
                    </div>
                </Col>
                <Col style={colsStyle} className="text-center fw-bold">
                    {burnLocked ? <LockFill /> : toAutoDecimals(item.combined.burn, 1) ?? "-"}
                </Col>
                {radioIds.map(r => (
                    <Col className=" d-flex justify-content-center" key={r} style={colsStyle}>
                        <div className={`score ${getSongScoreColorCss(toAutoDecimals(item.individual.find(i => i.radio_id === r)?.song_score, 1), true)}`}>
                            {toAutoDecimals(item.individual.find(i => i.radio_id === r)?.song_score, 1)}
                        </div>
                    </Col>
                ))}
            </Row>
        );
    }
}
