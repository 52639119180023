import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { axiosInstance } from "../../auth/Auth";
import moment from "moment/min/moment-with-locales";
import LoadingIndicator from "../LoadingIndicator";

export default function LatestDataRow({ radio, dataType }) {
    const [latestAvailableDate, setLatestAvailableDate] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function getLatestAvailableDate() {
            setLoading(true);
            const response = await axiosInstance.get("/radios/" + radio.radio_id + "/latest_calculated_dates");
            setLatestAvailableDate(response.data);
            setLoading(false);
        }
        getLatestAvailableDate();
    }, [radio]);

    return (
        <Row className="py-1 border-bottom align-items-center fs-8-xs">
            <Col xs={4} className=" align-content-center">
                {radio.radio_name}
            </Col>
            {loading ? (
                <Col>
                    <LoadingIndicator width={20} showText={false} />
                </Col>
            ) : (
                <>
                    <Col className=" align-content-center text-center">
                        {latestAvailableDate?.[dataType === 1 ? "stream" : "ppm"]?.song_deltas ? moment(latestAvailableDate).format("L").slice(0, -5) : "-"}
                    </Col>
                </>
            )}
        </Row>
    );
}
