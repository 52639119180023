import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import "../Login/styles.css";
import { Col, Container, Row } from "react-bootstrap";
import { ReactComponent as DataIcon } from "../../assets/LoginDataIcon.svg";
import Button from "react-bootstrap/Button";
import { useAuth } from "../../auth/Auth";

function Login() {
    const { token } = useAuth();
    const location = useLocation();

    //Redirect to home if we are already logged in
    if (token) {
        return <Navigate to="/" replace state={{ from: location }} />;
    }

    return (
        <Container fluid className=" bg-ra-dark-blue d-flex flex-column">
            <Row className={`d-none ${location.pathname === "/login" || location.pathname === "/login/" ? "d-xl-flex" : "d-none"}`}>
                <Col xs={12} className="p-0 text-end order-xl-first">
                    <Button onClick={() => window.open("https://radioanalyzer.com/client/login/", "_blank")} size="sm" className=" btn-ra-yellow px-5">
                        Looking for RadioAnalyzer PRO? Login here
                    </Button>
                </Col>
            </Row>
            <Row className=" h-100">
                <Col xl={6} className="d-flex justify-content-center align-items-center mb-5">
                    <Col xs={10} sm={8} md={6} lg={5} xl={8} xxl={7} className=" h-100 d-flex flex-column justify-content-center">
                        <DataIcon width={"auto"} height={"38vh"} className="p-5  align-self-center d-xl-none " /> <Outlet />
                    </Col>
                </Col>
                <Col xl={6} className=" d-none d-xl-block align-self-end">
                    <DataIcon width={"auto"} height={"80vh"} className=" pe-5" />
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <footer className="bg-beige text-dark p-1 fixed-bottom text-center terms row justify-content-center">
                        <Col xs={10}>
                            Use of this website constitutes acceptance of the RadioAnalyzer
                            <a
                                className="text-dark px-1 text-decoration-underline"
                                href="https://radioanalyzer.com/terms-conditions/"
                                rel="noreferrer"
                                target="_blank">
                                Terms and Conditions
                            </a>{" "}
                            and{" "}
                            <a className="text-dark text-decoration-underline px-1" href="https://radioanalyzer.com/privacy/" rel="noreferrer" target="_blank">
                                Privacy Notice.
                            </a>
                        </Col>
                    </footer>
                </Col>
            </Row>
        </Container>
    );
}

export default Login;
