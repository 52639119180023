import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../auth/Auth";
import SideNavigation from "../components/Navigation/SideNavigation";

function Layout() {
    const { token } = useAuth();
    const location = useLocation();

    if (!token) {
        return <Navigate to="/login" replace state={{ from: location }} />;
    }

    return (
        <>
            <SideNavigation />
            <main>
                <Outlet />
            </main>
        </>
    );
}

export default Layout;
