import React, { Suspense, useEffect, useRef, useState } from "react";
import "../SongSearch/styles.css";
import { Await, useRouteLoaderData } from "react-router-dom";
import { Button, Col, Table, Container, Form, Row, Stack, Popover, OverlayTrigger } from "react-bootstrap";
import { AsyncTypeahead, Typeahead } from "react-bootstrap-typeahead";
import { axiosInstance } from "../../auth/Auth";
import LoadingIndicator from "../../components/LoadingIndicator";
import { CaretDownFill, BarChartLineFill, CaretUpFill } from "react-bootstrap-icons";
import useSortableData from "../../components/useSortableData";
import TrendOverTime from "../../components/TrendOverTime";
import ExportData from "../../components/ExportData";
import ErrorResponse from "../../components/ErrorResponse";
import EmptyTableRow from "../../components/EmptyTableRow";

const SongSearch = () => {
    const [loading, setLoading] = useState(false);
    const [suggestionsLoading, setSuggestionsLoading] = useState({ artist: false, title: false });
    const [suggestionsOptions, setSuggestionsOptions] = useState({ artist: [], title: [] });
    const artistSearch = useRef("");
    const titleSearch = useRef("");
    const { radios } = useRouteLoaderData("root");
    const [errorCode, setErrorCode] = useState(null);
    const [station, setStation] = useState([]);

    const [songSearchData, setSongSearchData] = useState();
    const [showItemId, setShowItemId] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [hasTrendOverTime, setHasTrendOverTime] = useState(null);
    const [selectedRadioTOT, setSelectedRadioTOT] = useState([]);
    const [showTrendStations, setShowTrendStations] = useState(false);

    async function selectRadioTOT(selectedRadios) {
        setSelectedRadioTOT(selectedRadios);
        setHasTrendOverTime([]);
    }

    async function handleFormSubmit(event) {
        event.preventDefault();
        try {
            if ((artistSearch.current + titleSearch.current).length < 3) return;
            setLoading(true);
            const response = await axiosInstance.get(
                `/items/songs/?artist=${encodeURIComponent(artistSearch.current)}&title=${encodeURIComponent(titleSearch.current)}&radio_id=${
                    station.length > 0 ? station[0].radio_id : 0
                }`
            );
            setErrorCode(null);
            setSongSearchData(response.data);
        } catch (error) {
            setSongSearchData(null);
            setErrorCode(error?.response?.status ?? 500);
        }
        setLoading(false);
    }

    useEffect(() => {
        async function checkTrend(item_id) {
            const response = await axiosInstance.get(`/music_test/${+item_id}/item_on_radios?stream=true`);
            setHasTrendOverTime(response.data);
        }
        setHasTrendOverTime(null);
        if (selectedItemId) checkTrend(selectedItemId);
    }, [selectedItemId]);

    async function searchSuggestions(column, pattern) {
        setSuggestionsLoading(current => ({ ...current, [column]: true }));
        const response = await axiosInstance.get(`items/songs/${column}s/?${column}=${encodeURIComponent(pattern)}`);
        setSuggestionsOptions(current => ({ ...current, [column]: response.data }));
        setSuggestionsLoading(current => ({ ...current, [column]: false }));
    }

    const { sortedItems, requestSort, sortConfig } = useSortableData(songSearchData);

    const csvHeaders = ["Artist", "Title", "Release Year", "Total Spins", "Item ID"];
    const csvData = sortedItems?.map(x => [x.artist, x.title, x.release_year, x.spins, x.item_id]) ?? [];

    return (
        <Container fluid className="p-4">
            <Row>
                <Col className="bg-white p-4">
                    <h1 className="text-uppercase">Song Search</h1>
                    <Form onSubmit={handleFormSubmit} className="row">
                        <Col xs={12} xl={10} className="mb-xl-0">
                            <Row>
                                <Form.Group as={Col} className="mb-4 mb-xl-0 col-12 col-xl-4">
                                    <Form.Label>Artist</Form.Label>
                                    <AsyncTypeahead
                                        id="search-artist"
                                        isLoading={suggestionsLoading.artist}
                                        onSearch={pattern => searchSuggestions("artist", pattern)}
                                        minLength={3}
                                        className="autocomplete"
                                        options={suggestionsOptions.artist}
                                        clearButton={true}
                                        onChange={val => (val.length ? (artistSearch.current = val[0]) : "")}
                                        onInputChange={val => (artistSearch.current = val)}
                                        emptyLabel="No suggestions"
                                    />
                                </Form.Group>
                                <Form.Group as={Col} className="mb-4 mb-xl-0 col-12 col-xl-4">
                                    <Form.Label>Title</Form.Label>
                                    <AsyncTypeahead
                                        id="search-title"
                                        isLoading={suggestionsLoading.title}
                                        onSearch={pattern => searchSuggestions("title", pattern)}
                                        minLength={3}
                                        className="autocomplete"
                                        options={suggestionsOptions.title}
                                        clearButton={true}
                                        onChange={val => (val.length ? (titleSearch.current = val[0]) : "")}
                                        onInputChange={val => (titleSearch.current = val)}
                                        emptyLabel="No suggestions"
                                    />
                                </Form.Group>
                                <Suspense fallback={<span>Loading...</span>}>
                                    <Await resolve={radios}>
                                        {resolvedRadios => (
                                            <Form.Group controlId="song-search-station" as={Col} className="mb-3 mb-xl-0 col-12 col-xl-3">
                                                <Form.Label>Only search on:</Form.Label>
                                                <Typeahead
                                                    id="song-search-station-selection"
                                                    options={resolvedRadios.data}
                                                    labelKey="radio_name"
                                                    highlightOnlyResult
                                                    placeholder="Select station..."
                                                    clearButton={true}
                                                    onChange={setStation}
                                                    selected={station}
                                                />
                                            </Form.Group>
                                        )}
                                    </Await>
                                </Suspense>
                                <Form.Group as={Col} className="mb-1 mb-xl-0 col-12 col-xl-1">
                                    <Form.Label className=" text-nowrap">Item ID</Form.Label>
                                    <Form.Switch
                                        className="form-switch-lg"
                                        id="song-search-item-id"
                                        size="m"
                                        checked={showItemId}
                                        onChange={() => setShowItemId(!showItemId)}
                                    />
                                </Form.Group>
                            </Row>
                        </Col>
                        <Col xs={12} xl={2} className="text-center text-xl-end align-self-end">
                            <Button
                                type="submit"
                                className="btn-ra-yellow text-uppercase px-5 py-1 mb-xl-1"
                                disabled={(artistSearch.current + titleSearch.current).length < 3}>
                                Search
                            </Button>
                        </Col>
                    </Form>
                </Col>
            </Row>
            <hr className="m-2"></hr>
            <Row>
                <Col className="bg-white p-4">
                    {!loading && !errorCode && !songSearchData && (
                        <Container fluid className="p-4">
                            <Row>
                                <Col xs={12} md={12} lg={8}>
                                    <h6 className="fw-bold list-unstyled">What can you do with Song Search?</h6>
                                    <ul>
                                        <li>Search for songs based on artist and/or title </li>
                                        <li>Search your entire portfolio or only on a specific station</li>
                                        <li>You can check the song performance on your own station with the trend button</li>
                                    </ul>
                                    <h6 className="fw-bold">Nice to know</h6>
                                    <ul>
                                        <li>
                                            Just start typing and the system will suggest songs based on what you write, even just one word! (try typing “love”
                                            in the title bar for your valentines playlists..){" "}
                                        </li>
                                        <li>A great way to find less played songs by popular artists</li>
                                        <li>Songs are sorted by spins – click on any column header to change the sorting</li>
                                    </ul>
                                    <ul className="list-unstyled">
                                        <li className="fw-bold list-unstyled">Related reading:</li>
                                        <li className="list-unstyled ">
                                            <a
                                                className="text-dark text-decoration-none fst-italic"
                                                href="https://radioanalyzer.com/song-search/"
                                                target=" blank">
                                                Your Song
                                            </a>
                                        </li>
                                    </ul>
                                    <p>
                                        If you need further assistance in using Song Search, please contact{" "}
                                        <a
                                            className="text-dark fw-bold "
                                            href="mailto:training@radioanalyzer.com?subject=Training%20for%20Song%20Search"
                                            target=" blank">
                                            training@radioanalyzer.com
                                        </a>
                                    </p>
                                </Col>
                                <Col xs={12} md={12} xl={4}>
                                    <div className="iframe-styling">
                                        <iframe
                                            width="100%"
                                            height="100%"
                                            src="https://www.youtube.com/embed/8Ukv6ZiDqlQ?si=VlgqKUzFY7RtYiaC"
                                            title="How to use Song Search"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            allowFullScreen></iframe>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    )}
                    {!loading && errorCode && <ErrorResponse errorCode={errorCode} />}
                    {loading ? (
                        <span colSpan={12}>
                            <LoadingIndicator />
                        </span>
                    ) : (
                        songSearchData && (
                            <>
                                <Stack direction="horizontal" gap={1} className=" mb-2">
                                    <span className=" ms-auto">Download:</span>
                                    <ExportData
                                        data={csvData}
                                        headers={csvHeaders}
                                        filename={`Song search for ${artistSearch.current} ${titleSearch.current}${
                                            station.length > 0 ? "on station " + station[0].radio_name : ""
                                        }`}
                                    />
                                </Stack>
                                <Table size="sm" hover responsive className="song-search-table">
                                    <thead>
                                        <tr>
                                            <th className="col-4" onClick={() => requestSort("artist")}>
                                                <Stack direction="horizontal">
                                                    <span>Artist</span>
                                                    <span className="col-1">
                                                        {sortConfig?.key === "artist" &&
                                                            (sortConfig.direction === "descending" ? <CaretDownFill /> : <CaretUpFill />)}
                                                    </span>
                                                </Stack>
                                            </th>
                                            <th className="col-4" onClick={() => requestSort("title")}>
                                                <Stack direction="horizontal">
                                                    <span>Title</span>
                                                    <span className="col-1">
                                                        {sortConfig?.key === "title" &&
                                                            (sortConfig.direction === "descending" ? <CaretDownFill /> : <CaretUpFill />)}
                                                    </span>
                                                </Stack>
                                            </th>
                                            <th className="col-2" onClick={() => requestSort("release_year")}>
                                                <Stack direction="horizontal" className="justify-content-center text-center">
                                                    <span className="offset-1">Release Year</span>
                                                    <span className="col-1">
                                                        {sortConfig?.key === "release_year" &&
                                                            (sortConfig.direction === "descending" ? <CaretDownFill /> : <CaretUpFill />)}
                                                    </span>
                                                </Stack>
                                            </th>
                                            <th className="col-2" onClick={() => requestSort("total_spins")}>
                                                <Stack direction="horizontal" className="justify-content-center text-center">
                                                    <span className="offset-1">Total Spins</span>
                                                    <span className="col-1">
                                                        {sortConfig?.key === "total_spins" &&
                                                            (sortConfig.direction === "descending" ? <CaretDownFill /> : <CaretUpFill />)}
                                                    </span>
                                                </Stack>
                                            </th>
                                            {showItemId && (
                                                <th className="col-2" onClick={() => requestSort("item_id")}>
                                                    <Stack direction="horizontal" className="justify-content-center text-center">
                                                        <span className="offset-1">Item ID</span>
                                                        <span className="col-1">
                                                            {sortConfig?.key === "item_id" &&
                                                                (sortConfig.direction === "descending" ? <CaretDownFill /> : <CaretUpFill />)}
                                                        </span>
                                                    </Stack>
                                                </th>
                                            )}
                                            <th className="text-center col-2">Trend Over Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {sortedItems.length ? (
                                            sortedItems.map(song => (
                                                <tr key={song.item_id} className={`text-center`}>
                                                    <td className="text-truncate text-start">{song.artist}</td>
                                                    <td className="text-truncate text-start">{song.title}</td>
                                                    <td className="text-center">{song.release_year}</td>
                                                    <td className="text-center">{song.spins}</td>
                                                    {showItemId ? <td className="text-center">{song.item_id}</td> : ""}
                                                    <td className="text-center">
                                                        <Suspense fallback={<span>Loading...</span>}>
                                                            <Await resolve={radios}>
                                                                {resolvedRadios => (
                                                                    <OverlayTrigger
                                                                        trigger={"click"}
                                                                        show={
                                                                            showTrendStations &&
                                                                            selectedItemId === song.item_id &&
                                                                            selectedRadioTOT.length === 0
                                                                        }
                                                                        rootClose={true}
                                                                        onToggle={val => setShowTrendStations(song.item_id === selectedItemId ? val : true)}
                                                                        placement="bottom"
                                                                        flip={true}
                                                                        overlay={
                                                                            <Popover>
                                                                                <Popover.Body className=" p-0">
                                                                                    <Typeahead
                                                                                        id={`dropdown-stations-tot-${song.item_id}`}
                                                                                        options={resolvedRadios?.data
                                                                                            .filter(radio => radio.stream_settings.song_deltas)
                                                                                            .map(radio => ({
                                                                                                ...radio,
                                                                                                disabled: !hasTrendOverTime?.includes(radio.radio_id),
                                                                                            }))}
                                                                                        labelKey="radio_name"
                                                                                        renderMenu={!hasTrendOverTime ? () => <LoadingIndicator /> : undefined}
                                                                                        clearButton={true}
                                                                                        onChange={selectRadioTOT}
                                                                                        selected={selectedRadioTOT}
                                                                                        open={true}
                                                                                    />
                                                                                </Popover.Body>
                                                                            </Popover>
                                                                        }>
                                                                        <Button
                                                                            className="btn-ra-yellow"
                                                                            size="sm"
                                                                            onClick={() => {
                                                                                setSelectedItemId(song.item_id);
                                                                            }}>
                                                                            <BarChartLineFill /> Trend
                                                                        </Button>
                                                                    </OverlayTrigger>
                                                                )}
                                                            </Await>
                                                        </Suspense>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <EmptyTableRow />
                                        )}
                                    </tbody>
                                </Table>
                            </>
                        )
                    )}
                </Col>
            </Row>
            <TrendOverTime
                show={selectedRadioTOT.length > 0 && !!selectedItemId}
                onHide={() => {
                    setSelectedItemId(null);
                    setSelectedRadioTOT([]);
                    setHasTrendOverTime(null);
                }}
                radioId={selectedRadioTOT[0]?.radio_id}
                itemId={selectedItemId}
                dataType={1}
                daypartId={1}
                monthsLimit={8}
            />
        </Container>
    );
};

export default SongSearch;
