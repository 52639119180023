import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { CaretUpFill } from "react-bootstrap-icons";

export default function BackToTopButton({ smooth = true, scrollFromTop = 500 }) {
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        function handleScroll() {
            if (window.scrollY > scrollFromTop) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        }

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [scrollFromTop]);

    function scrollToTop() {
        if (smooth) {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        } else {
            window.scrollTo(0, 0);
        }
    }

    return (
        <Button size="sm" className={`${showButton ? "d-block" : "d-none"} btn-back-to-top`} onClick={scrollToTop}>
            <CaretUpFill width={30} height={30} />
        </Button>
    );
}
