import { Route, createBrowserRouter, createRoutesFromElements, Navigate, defer } from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import PlaylistAutopilot from "./pages/PlaylistAutopilot/PlaylistAutopilot";
import SongSearch from "./pages/SongSearch/SongSearch";
import MusicTestReports from "./pages/MusicTestReports/MusicTestReports";
import Settings from "./pages/Settings/Settings";
import AuthLayout from "./auth/AuthLayout";
import Layout from "./pages/Layout";
import Login from "./pages/Login/Login";
import { axiosInstance } from "./auth/Auth";
import ForgotPassword from "./pages/Login/ForgotPassword";
import LoginForm from "./pages/Login/LoginForm";
import ResetPassword from "./pages/Login/ResetPassword";
import NotFound from "./pages/NotFound/NotFound";
import Error from "./pages/Error/Error";
import ErrorBoundary from "./components/ErrorBoundary";
import MusicTesting from "./pages/MusicTest/MusicTesting";

function getRadios() {
    return axiosInstance.get("users/current/radios/?stream=true");
}

function getUserInfo() {
    return axiosInstance.get("users/current/");
}

function updateLastLogin() {
    return axiosInstance.patch("users/current/update_last_login/");
}

function getDayparts() {
    return axiosInstance.get(`users/current/dayparts/music_test/`);
}

function getCompetitorRadios() {
    return axiosInstance.get("users/current/competitor_radios/");
}

function getAllRadioNames() {
    return axiosInstance.get("radios/names/");
}
function getDemographics() {
    return axiosInstance.get(`demographics/`);
}

function getRadioTags() {
    return axiosInstance.get("users/current/tags/");
}

function getAirplayMarkets() {
    return axiosInstance.get(`/users/current/airplay_markets/`);
}

function getCompetitorMarkets() {
    return axiosInstance.get(`/users/current/competitor_markets/`);
}
function getPlaylistAutopilotSongCategories() {
    return axiosInstance.get("playlist_autopilot/categories/");
}

async function rootLoader({ params }) {
    return defer({
        last_login: updateLastLogin(),
        radios: getRadios(),
        allRadioNames: getAllRadioNames(),
        competitorRadios: getCompetitorRadios(),
        radioTags: getRadioTags(),
        airplayMarkets: getAirplayMarkets(),
        competitorMarkets: getCompetitorMarkets(),
        userInfo: getUserInfo(),
        dayparts: getDayparts(),
        userDayparts: getDayparts(),
        demographics: getDemographics(),
    });
}

function getMusicTestReports() {
    return axiosInstance.get("/reports/music_test/current_user/");
}

async function musicTestReportsLoader({ params }) {
    return defer({ reports: getMusicTestReports() });
}

async function playlistAutopilotLoader() {
    return defer({
        songCategories: getPlaylistAutopilotSongCategories(),
    });
}

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route element={<AuthLayout />}>
            <Route element={<Layout />} loader={rootLoader} id={"root"} shouldRevalidate={() => false} errorElement={<ErrorBoundary />}>
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="music_test" element={<MusicTesting />} />
                <Route path="playlist_autopilot" element={<PlaylistAutopilot />} loader={playlistAutopilotLoader} />
                <Route path="song_search" element={<SongSearch />} />
                <Route path="music_test_reports" element={<MusicTestReports />} loader={musicTestReportsLoader} />
                <Route path="settings" element={<Settings />} />
            </Route>
            <Route path="login" element={<Login />}>
                <Route index element={<LoginForm />} />
                <Route path="forgot_password" element={<ForgotPassword />} />
                <Route path="reset_password/:token" element={<ResetPassword />} />
            </Route>
            <Route path="error" element={<Error />} />
            <Route path="/" element={<Navigate to={"dashboard"} />} />
            <Route path="*" element={<NotFound />} />
        </Route>
    )
);
