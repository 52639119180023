import React, { Suspense, useState, useEffect, useRef, useMemo } from "react";
import { Await, useLoaderData, useRevalidator, useRouteLoaderData } from "react-router-dom";
import { Button, Col, Table, Container, Form, Row, Collapse, ButtonGroup, Stack, InputGroup, ToggleButton, ToggleButtonGroup, Card } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { axiosInstance } from "../../auth/Auth";
import LoadingIndicator from "../../components/LoadingIndicator";
import { CaretDownFill, CaretUpFill, PencilFill, TrashFill, XLg, CheckLg, QuestionCircleFill, Grid3x3GapFill, Search, List } from "react-bootstrap-icons";
import "../MusicTestReports/styles.css";
import ConfirmPrompt from "../../components/ConfirmPrompt";
import useSortableData from "../../components/useSortableData";
import { findRadioDayparts, formatDaypartText } from "../../components/HelperFunctions";
import ErrorResponse from "../../components/ErrorResponse";

const MusicTestReports = () => {
    const [errorCode, setErrorCode] = useState(null);
    const { radios, userInfo, dayparts } = useRouteLoaderData("root");
    const { reports } = useLoaderData();
    const [reportsData, setReportsData] = useState(null);
    const [selectedReport, setSelectedReport] = useState(null);
    const [advancedOpen, setAdvancedOpen] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [deleteReportId, setDeleteReportId] = useState(null);
    const [radioNames, setRadioNames] = useState([]);
    const emailInputChangeRef = useRef(null);
    const emailInputRef = useRef(null);
    const revalidator = useRevalidator();
    const emptyFormData = {
        station: [],
        email_address: [],
        run_frequency: 2,
        weeks: 2,
        spins_threshold: 3,
        daypart_id: "placeholder",
    };
    const [formData, setFormData] = useState(emptyFormData);
    const weeksOptions = Array.from({ length: 52 }, (_, i) => i + 1);
    const spinsOptions = Array.from({ length: 50 }, (_, i) => i + 1);
    const sendsOptions = Array.from({ length: 25 }, (_, i) => i + 1);
    const [display, setDisplay] = useState(1);

    useEffect(() => {
        async function setData() {
            const radioIds = (await radios).data.map(x => x.radio_id);
            setReportsData((await reports).data.filter(x => x.demo_id === 1 && radioIds.includes(x.radio_id)));
        }
        setData();
    }, [radios, reports]);

    useEffect(() => {
        async function getEmailAddress() {
            const userInfoData = (await userInfo).data;
            if (userInfoData.email) {
                setFormData(current => ({
                    ...current,
                    email_address: [{ email: userInfoData.email }],
                }));
            }
        }
        getEmailAddress();
    }, [userInfo]);

    async function handleFormSubmit(event) {
        event.preventDefault();
        const resolvedUser = (await userInfo).data;
        const input = {
            user_id: resolvedUser.user_id,
            radio_id: formData.station[0].radio_id,
            email_address: formData.email_address.map(x => x.email).join(",") ?? [],
            run_frequency: formData.run_frequency,
            weeks: formData.weeks,
            spins_threshold: formData.spins_threshold,
            daypart_id: formData.daypart_id,
            demo_id: 1,
        };
        try {
            const response = await axiosInstance.post("reports/music_test/", input);
            revalidator.revalidate();
            setFormData({ ...emptyFormData, email_address: resolvedUser.email ? [{ email: resolvedUser.email }] : [] });
            setErrorCode(null);
        } catch (error) {
            setErrorCode(error?.response?.status ?? 500);
        }
    }

    const handleEmailInput = value => {
        const validEmails = value.filter(el => validateEmail(el.email));
        setFormData({ ...formData, email_address: validEmails });
    };
    const addEmailOnEnter = value => {
        if (validateEmail(value.trim())) {
            setFormData({ ...formData, email_address: [...formData.email_address, { email: value.trim() }] });
            emailInputRef.current.clear();
        }
    };
    const handleEmailChange = value => {
        const validEmails = value.filter(el => validateEmail(el.email));
        setSelectedReport({ ...selectedReport, email_address: validEmails });
    };
    const addEmailOnEnterChange = value => {
        if (validateEmail(value.trim())) {
            setSelectedReport({ ...selectedReport, email_address: [...selectedReport.email_address, { email: value.trim() }] });
            emailInputChangeRef.current.clear();
        }
    };
    const validateEmail = email => {
        const emailPattern = /\S+@\S+\.\S+/;
        return emailPattern.test(email);
    };

    async function handleSaveClick(report) {
        try {
            const response = await axiosInstance.put("/reports/music_test/" + report.report_id, {
                ...report,
                email_address: report.email_address.map(x => x.email).join(","),
            });
            revalidator.revalidate();
            setSelectedReport(null);
            setErrorCode(null);
        } catch (error) {
            setErrorCode(error?.response?.status ?? 500);
        }
    }

    async function deleteMusicTestReport(id) {
        try {
            const response = await axiosInstance.delete("/reports/music_test/" + id);
            revalidator.revalidate();
            setDeleteReportId(null);
            setErrorCode(null);
        } catch (error) {
            setErrorCode(error?.response?.status ?? 500);
        }
    }
    function handleDisplayChange(value) {
        setDisplay(value);
    }

    useEffect(() => {
        async function getRadioNames() {
            const radiosData = (await radios).data;
            setRadioNames(
                radiosData.map(x => {
                    return { radio_id: x.radio_id, radio_name: x.radio_name };
                })
            );
        }
        if (radios) {
            getRadioNames();
        }
    }, [radios]);

    const getRadioName = radio_id => radioNames?.find(x => x.radio_id === radio_id).radio_name;

    const musictTestReportsMemo = useMemo(() => {
        if (reportsData) {
            let filteredData = reportsData;
            if (searchValue) {
                filteredData = filteredData.filter(
                    x =>
                        getRadioName(x.radio_id).toLowerCase().includes(searchValue.toLowerCase().trim()) ||
                        x.radio_id.toString().includes(searchValue.trim()) ||
                        x.email_address.toLowerCase().includes(searchValue.toLowerCase().trim()) ||
                        x.spins_threshold?.toString().includes(searchValue.trim()) ||
                        x.weeks?.toString().includes(searchValue.trim()) ||
                        x.run_frequency?.toString().includes(searchValue.trim())
                );
            }
            return filteredData;
        }
    }, [reportsData, searchValue]);

    const { sortedItems, requestSort, sortConfig } = useSortableData(musictTestReportsMemo);

    return (
        <Container fluid className="p-4 ">
            <Row>
                <Col className="bg-white p-4">
                    <Form className="row" onSubmit={handleFormSubmit}>
                        <Col xs={12} xl className="order-xl-last mb-xl-0 ">
                            <h1 className="text-uppercase">Music Test Reports</h1>
                            <div className="d-flex flex-column justify-content-between align-items-xl-start">
                                <p className="ps-1">See your reports below, or set up a new one with a click.</p>
                                <Button
                                    type="button"
                                    onClick={() => setAdvancedOpen(!advancedOpen)}
                                    className="btn-ra-yellow text-uppercase px-4 py-xl-1"
                                    aria-controls="music-test-advanced"
                                    aria-expanded={advancedOpen}>
                                    SET UP NEW
                                </Button>
                            </div>
                            <Collapse in={advancedOpen}>
                                <Row>
                                    <Col xs={12} xl={10}>
                                        <Row className="pt-4 " id="music-test-advanced ">
                                            <Suspense fallback={<span>Loading...</span>}>
                                                <Await resolve={radios}>
                                                    {resolvedRadios => (
                                                        <Form.Group controlId="music-test-report-stations" as={Col} className="mb-3 mb-xl-0 col-12 col-xl-3">
                                                            <Form.Label>Station</Form.Label>
                                                            <Typeahead
                                                                id="music-test-reports-station"
                                                                options={resolvedRadios.data}
                                                                labelKey={"radio_name"}
                                                                highlightOnlyResult
                                                                placeholder="Select station..."
                                                                clearButton={true}
                                                                onChange={value => setFormData({ ...formData, station: value })}
                                                                selected={formData.station}
                                                            />
                                                        </Form.Group>
                                                    )}
                                                </Await>
                                            </Suspense>
                                            <Suspense>
                                                <Await resolve={dayparts}>
                                                    {resolvedDayparts => (
                                                        <Form.Group controlId="music-test-daypart" as={Col} className="mb-3 mb-xl-0 col-12 col-xl-3">
                                                            <Form.Label>Daypart</Form.Label>
                                                            <Form.Select
                                                                value={formData.daypart_id}
                                                                onChange={e =>
                                                                    setFormData({
                                                                        ...formData,
                                                                        daypart_id: Number(e.target.value),
                                                                    })
                                                                }
                                                                disabled={!formData.station.length}>
                                                                <option value={"placeholder"} disabled>
                                                                    {formData.station.length ? "Select daypart..." : "Select station first..."}
                                                                </option>
                                                                {findRadioDayparts(resolvedDayparts.data, formData.station[0])
                                                                    .sort((a, b) => a.daypart_id - b.daypart_id)
                                                                    .map(x => (
                                                                        <option key={x.daypart_id} value={x.daypart_id}>
                                                                            {formatDaypartText(x)}
                                                                        </option>
                                                                    ))}
                                                            </Form.Select>
                                                        </Form.Group>
                                                    )}
                                                </Await>
                                            </Suspense>
                                        </Row>

                                        <Row xs={12} xl={4} className="my-0 my-xl-3 row-cols-md-3 row-cols-1">
                                            <Form.Group as={Col} className="mb-3 mb-xl-0 ">
                                                <Form.Label>
                                                    Weeks
                                                    <span className="tooltip-info">
                                                        <QuestionCircleFill className="mx-1 tooltip-info" />
                                                        <span className="tooltiptext">
                                                            <p>Number of weeks of data used for calculation.</p>
                                                        </span>
                                                    </span>
                                                </Form.Label>
                                                <Col>
                                                    <Form.Select
                                                        className="d-inline-block  px-2"
                                                        value={formData.weeks}
                                                        onChange={e =>
                                                            setFormData({
                                                                ...formData,
                                                                weeks: e.target.value,
                                                            })
                                                        }>
                                                        {weeksOptions.map(week => (
                                                            <option key={week} value={week}>
                                                                {week} {week === 1 ? "week" : "weeks"}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Col} className="mb-3 mb-xl-0">
                                                <Form.Label>Minimum spins</Form.Label>
                                                <Col>
                                                    <Form.Select
                                                        className=" d-inline-block  px-2"
                                                        value={formData.spins_threshold}
                                                        onChange={e =>
                                                            setFormData({
                                                                ...formData,
                                                                spins_threshold: e.target.value,
                                                            })
                                                        }>
                                                        {spinsOptions.map(spin => (
                                                            <option key={spin} value={spin}>
                                                                {spin} {spin === 1 ? "spin" : "spins"}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Col} className="mb-3 mb-xl-0">
                                                <Form.Label>
                                                    Frequency
                                                    <span className="tooltip-info">
                                                        <QuestionCircleFill className="mx-1 tooltip-info" />
                                                        <span className="tooltiptext">
                                                            <p>How often the report will be sent.</p>
                                                        </span>
                                                    </span>
                                                </Form.Label>
                                                <Col>
                                                    <Form.Select
                                                        className=" d-inline-block px-2"
                                                        value={formData.run_frequency}
                                                        onChange={e =>
                                                            setFormData({
                                                                ...formData,
                                                                run_frequency: e.target.value,
                                                            })
                                                        }
                                                        min={1}>
                                                        {sendsOptions.map(send => (
                                                            <option key={send} value={send}>
                                                                every {send} {send === 1 ? "week" : "weeks"}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                </Col>
                                            </Form.Group>
                                        </Row>
                                        <div className="my-2" />
                                        <Row>
                                            <Form.Group controlId="music-test-report-email" as={Col} className="mb-3 mb-xl-0 col-12 col-xl-12">
                                                <Form.Label>Send to</Form.Label>
                                                <Col>
                                                    <Typeahead
                                                        id="music-test-reports-email"
                                                        ref={emailInputRef}
                                                        multiple
                                                        labelKey={"email"}
                                                        options={[]}
                                                        placeholder="Add email address(es)..."
                                                        allowNew={true}
                                                        onChange={handleEmailInput}
                                                        selected={formData.email_address}
                                                        onKeyDown={e => {
                                                            if (e.key === "Enter" || e.key === " ") {
                                                                const value = emailInputRef.current.getInput().value;
                                                                addEmailOnEnter(value);
                                                            }
                                                        }}
                                                    />
                                                </Col>
                                            </Form.Group>
                                        </Row>
                                    </Col>
                                    <Col xs={12} xl={2} className="text-center text-xl-end align-self-end ms-auto">
                                        <Button
                                            type="submit"
                                            className="btn-ra-yellow text-uppercase px-5"
                                            disabled={!formData.station.length || !formData.email_address.length || formData.daypart_id === "placeholder"}>
                                            SET UP
                                        </Button>
                                    </Col>
                                </Row>
                            </Collapse>
                        </Col>
                    </Form>
                </Col>
            </Row>
            <hr className="m-2"></hr>
            <Row>
                <Col className="bg-white p-4">
                    {!reportsData?.length > 0 && !errorCode && (
                        <Container fluid className="p-4">
                            <Row>
                                <Col xs={12} md={12} lg={8}>
                                    <h6 className="fw-bold list-unstyled">What can you do with Music Test Reports?</h6>
                                    <ul>
                                        <li>Automate and share your music testing lists</li>
                                        <li>Choose from one week to one entire year’s worth of data</li>
                                        <li>Set minimum spins to help filter your lists</li>
                                    </ul>
                                    <h6 className="fw-bold">Nice to know</h6>
                                    <ul>
                                        <li>Downloads available as Excel, CSV, or HTML</li>
                                        <li>Add any number of emails, even if they don’t have a RadioAnalyzer login</li>
                                        <li>Dayparts can be useful for unique perspectives, but be sure to have enough spins on the song to get a good read</li>
                                    </ul>

                                    <p>
                                        If you need further assistance in using Music Test Report, please contact{" "}
                                        <a
                                            className="text-dark fw-bold "
                                            href="mailto:training@radioanalyzer.com?subject=Training%20for%20Music%20Test%20Reports"
                                            target=" blank">
                                            training@radioanalyzer.com
                                        </a>
                                    </p>
                                </Col>
                                <Col xs={12} md={12} xl={4}>
                                    <div className="iframe-styling">
                                        <iframe
                                            width="100%"
                                            height="100%"
                                            src="https://www.youtube.com/embed/JEYOVS0Tib4?si=7WlzC9WmWMXY1dtZ"
                                            title="How to use
                                            Song Delta Reports"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;
                                            web-share"
                                            allowFullScreen></iframe>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    )}
                    {reportsData && errorCode && <ErrorResponse errorCode={errorCode} />}
                    {!reportsData ? (
                        <span colSpan={12}>
                            <LoadingIndicator />
                        </span>
                    ) : (
                        reportsData.length > 0 && (
                            <>
                                <Stack direction="horizontal" gap={2} className=" flex-wrap">
                                    <p>Here you'll find an overview of your existing Music Test Reports. Add new reports on the top.</p>
                                </Stack>
                                <Stack direction="horizontal" gap={2} className="mb-3 flex-wrap">
                                    <Col xs={12} xl={8}>
                                        <InputGroup>
                                            <InputGroup.Text className="input-icon">
                                                <Search />
                                            </InputGroup.Text>
                                            <Form.Control
                                                value={searchValue}
                                                onChange={e => {
                                                    setSearchValue(e.target.value);
                                                }}
                                                placeholder="search by radio name, spins or email address"
                                            />
                                        </InputGroup>
                                    </Col>
                                    <div className=" ms-xl-auto d-none d-sm-block">
                                        <Form.Group
                                            controlId="new-music-alerts-toggle-button"
                                            as={Col}
                                            className="d-flex flex-column py-3 mb-xl-0 col-1 col-xl-1">
                                            <ToggleButtonGroup
                                                type={"radio"}
                                                name="display"
                                                size="sm"
                                                value={display}
                                                onChange={handleDisplayChange}
                                                className="ra-switcher">
                                                <ToggleButton id={`display-card`} variant={"ra-dark-blue-outline"} value={1}>
                                                    <Grid3x3GapFill />
                                                </ToggleButton>
                                                <ToggleButton id={`display-list`} variant={"ra-dark-blue-outline"} value={2}>
                                                    <List />
                                                </ToggleButton>
                                            </ToggleButtonGroup>
                                        </Form.Group>
                                    </div>
                                </Stack>
                                {display === 2 && (
                                    <Table size="sm" hover responsive className="music-test-report-table">
                                        <thead>
                                            <tr>
                                                <th className="col-2" onClick={() => requestSort("radio_id")}>
                                                    <Stack direction="horizontal">
                                                        <span className="text-start">Radio Name</span>
                                                        <span className="col-1">
                                                            {sortConfig?.key === "radio_id" &&
                                                                (sortConfig.direction === "descending" ? <CaretDownFill /> : <CaretUpFill />)}
                                                        </span>
                                                    </Stack>
                                                </th>
                                                <th className="col-1" onClick={() => requestSort("spins")}>
                                                    <Stack direction="horizontal" className="justify-content-center">
                                                        <span className="offset-1">Spins</span>
                                                        <span className="col-1">
                                                            {sortConfig?.key === "spins" &&
                                                                (sortConfig.direction === "descending" ? <CaretDownFill /> : <CaretUpFill />)}
                                                        </span>
                                                    </Stack>
                                                </th>
                                                <th className="col-1" onClick={() => requestSort("weeks")}>
                                                    <Stack direction="horizontal" className="justify-content-center">
                                                        <span className="offset-1">Weeks</span>
                                                        <span className="col-1">
                                                            {sortConfig?.key === "weeks" &&
                                                                (sortConfig.direction === "descending" ? <CaretDownFill /> : <CaretUpFill />)}
                                                        </span>
                                                    </Stack>
                                                </th>
                                                <th className="col-1" onClick={() => requestSort("run_frequency")}>
                                                    <Stack direction="horizontal" className="justify-content-center">
                                                        <span className="offset-1">Frequency</span>
                                                        <span className="col-1">
                                                            {sortConfig?.key === "run_frequency" &&
                                                                (sortConfig.direction === "descending" ? <CaretDownFill /> : <CaretUpFill />)}
                                                        </span>
                                                    </Stack>
                                                </th>
                                                <th className="col-2" onClick={() => requestSort("daypart_id")}>
                                                    <Stack direction="horizontal" className="justify-content-center">
                                                        <span className="offset-1">Daypart</span>
                                                        <span className="col-1">
                                                            {sortConfig?.key === "daypart_id" &&
                                                                (sortConfig.direction === "descending" ? <CaretDownFill /> : <CaretUpFill />)}
                                                        </span>
                                                    </Stack>
                                                </th>
                                                <th className="col-4" onClick={() => requestSort("email_address")}>
                                                    <Stack direction="horizontal" className="justify-content-center">
                                                        <span className="offset-1">Email address(es)</span>
                                                        <span className="col-1">
                                                            {sortConfig?.key === "email_address" &&
                                                                (sortConfig.direction === "descending" ? <CaretDownFill /> : <CaretUpFill />)}
                                                        </span>
                                                    </Stack>
                                                </th>
                                                <th className="col-1"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {sortedItems.map(report => (
                                                <tr key={report.report_id} className={`text-center`}>
                                                    <Suspense>
                                                        <Await resolve={radios}>
                                                            {resolvedRadios => (
                                                                <td className="text-start">
                                                                    {resolvedRadios?.data?.filter(radio => radio.radio_id === report.radio_id)[0]?.radio_name}
                                                                </td>
                                                            )}
                                                        </Await>
                                                    </Suspense>
                                                    <td>{report.spins_threshold}</td>
                                                    <td>{report.weeks}</td>
                                                    <td>{report.run_frequency}</td>
                                                    <td>
                                                        <Suspense>
                                                            <Await resolve={dayparts}>
                                                                {resolvedDayparts => (
                                                                    <span>
                                                                        {formatDaypartText(
                                                                            findRadioDayparts(resolvedDayparts.data, report.radio_id).find(
                                                                                x => x.daypart_id === report.daypart_id
                                                                            )
                                                                        )}
                                                                    </span>
                                                                )}
                                                            </Await>
                                                        </Suspense>
                                                    </td>
                                                    <td className="p-0">
                                                        {selectedReport?.report_id === report.report_id ? (
                                                            <Typeahead
                                                                ref={emailInputChangeRef}
                                                                id="music-test-reports-email-edit"
                                                                multiple
                                                                labelKey={"email"}
                                                                size="sm"
                                                                options={[]}
                                                                open={false}
                                                                allowNew={true}
                                                                onChange={handleEmailChange}
                                                                selected={selectedReport?.email_address}
                                                                onKeyDown={e => {
                                                                    if (e.key === "Enter" || e.key === " ") {
                                                                        let value = emailInputChangeRef.current.getInput().value;
                                                                        addEmailOnEnterChange(value);
                                                                    }
                                                                }}
                                                            />
                                                        ) : (
                                                            <span>{report.email_address.split(",").join(", ")}</span>
                                                        )}
                                                    </td>
                                                    <td>
                                                        {selectedReport?.report_id === report.report_id ? (
                                                            <ButtonGroup size="sm">
                                                                <Button
                                                                    variant="success"
                                                                    title="Save"
                                                                    disabled={!selectedReport?.email_address.length}
                                                                    onClick={() => handleSaveClick(selectedReport)}>
                                                                    <CheckLg />
                                                                </Button>
                                                                <Button variant="danger" title="Discard" onClick={() => setSelectedReport(null)}>
                                                                    <XLg />
                                                                </Button>
                                                            </ButtonGroup>
                                                        ) : (
                                                            <ButtonGroup size="sm">
                                                                <Button
                                                                    variant="primary"
                                                                    title={"Edit"}
                                                                    onClick={() =>
                                                                        setSelectedReport({
                                                                            ...report,
                                                                            email_address: report.email_address.split(",").map(x => {
                                                                                return { email: x };
                                                                            }),
                                                                        })
                                                                    }>
                                                                    <PencilFill />
                                                                </Button>
                                                                <Button variant="danger" title={"Delete"} onClick={() => setDeleteReportId(report.report_id)}>
                                                                    <TrashFill />
                                                                </Button>
                                                            </ButtonGroup>
                                                        )}
                                                    </td>
                                                    <ConfirmPrompt
                                                        show={deleteReportId}
                                                        handleClose={() => setDeleteReportId(null)}
                                                        handleConfirm={async () => await deleteMusicTestReport(deleteReportId)}
                                                    />
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                )}
                                {display === 1 && (
                                    <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 row-cols-xl-2 row-cols-xxl-3">
                                        {sortedItems.length ? (
                                            sortedItems.map((report, index) => (
                                                <div key={index} className="col mb-4">
                                                    <Card className="w-100 ">
                                                        <Card.Header className="bg-ra-dark-blue text-light d-flex justify-content-between align-items-center text-center font-weight-bold">
                                                            <div className="d-flex justify-content-center align-items-center fw-bold fs-6 text flex-grow-1">
                                                                <Suspense>
                                                                    <Await resolve={radios}>
                                                                        {resolvedRadios => (
                                                                            <td className="text-start">
                                                                                {
                                                                                    resolvedRadios?.data?.filter(radio => radio.radio_id === report.radio_id)[0]
                                                                                        ?.radio_name
                                                                                }
                                                                            </td>
                                                                        )}
                                                                    </Await>
                                                                </Suspense>
                                                            </div>
                                                            {selectedReport?.report_id === report.report_id ? (
                                                                <ButtonGroup size="sm" className="pe-1">
                                                                    <Button
                                                                        variant="success"
                                                                        title="Save"
                                                                        disabled={!selectedReport?.email_address.length}
                                                                        onClick={() => handleSaveClick(selectedReport)}>
                                                                        <CheckLg />
                                                                    </Button>
                                                                    <Button variant="danger" title="Discard" onClick={() => setSelectedReport(null)}>
                                                                        <XLg />
                                                                    </Button>
                                                                </ButtonGroup>
                                                            ) : (
                                                                <ButtonGroup size="sm" className="pe-1">
                                                                    <Button
                                                                        variant="primary"
                                                                        title={"Edit"}
                                                                        onClick={() =>
                                                                            setSelectedReport({
                                                                                ...report,
                                                                                email_address: report.email_address.split(",").map(x => ({ email: x })),
                                                                            })
                                                                        }>
                                                                        <PencilFill />
                                                                    </Button>
                                                                    <Button
                                                                        variant="danger"
                                                                        title={"Delete"}
                                                                        onClick={() => setDeleteReportId(report.report_id)}>
                                                                        <TrashFill />
                                                                    </Button>
                                                                </ButtonGroup>
                                                            )}
                                                        </Card.Header>

                                                        <Card.Body>
                                                            <Card.Text className="text-center ">
                                                                <>
                                                                    <span>
                                                                        send every <strong>{report.run_frequency}</strong> weeks
                                                                    </span>
                                                                    <div className="vr d-xl-inline-block mx-2"></div>
                                                                    <span>
                                                                        <strong>{report.weeks}</strong> weeks
                                                                    </span>
                                                                </>
                                                            </Card.Text>

                                                            <Card.Text className="text-center ">
                                                                <Suspense>
                                                                    <Await resolve={dayparts}>
                                                                        {resolvedDayparts => (
                                                                            <div>
                                                                                min <strong>{report.spins_threshold}</strong> spins
                                                                                <div className="vr d-xl-inline-block mx-2"></div>
                                                                                <span className="bold">
                                                                                    {formatDaypartText(
                                                                                        findRadioDayparts(resolvedDayparts.data, report.radio_id).find(
                                                                                            x => x.daypart_id === report.daypart_id
                                                                                        )
                                                                                    )}
                                                                                </span>
                                                                            </div>
                                                                        )}
                                                                    </Await>
                                                                </Suspense>
                                                            </Card.Text>
                                                            <Card.Text className="music-reports-email-card-edit text-center">
                                                                {selectedReport?.report_id === report.report_id ? (
                                                                    <Typeahead
                                                                        ref={emailInputChangeRef}
                                                                        id="music-test-reports-email-edit"
                                                                        multiple
                                                                        labelKey={"email"}
                                                                        size="sm"
                                                                        options={[]}
                                                                        open={false}
                                                                        allowNew={true}
                                                                        onChange={handleEmailChange}
                                                                        selected={selectedReport?.email_address}
                                                                        onKeyDown={e => {
                                                                            if (e.key === "Enter" || e.key === " ") {
                                                                                let value = emailInputChangeRef.current.getInput().value;
                                                                                addEmailOnEnterChange(value);
                                                                            }
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <span className="rbt-token p-2">{report.email_address.split(",").join(", ")}</span>
                                                                )}
                                                            </Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            ))
                                        ) : (
                                            <div className="row justify-content-center">
                                                <div className="col-12">
                                                    <span>No results.</span>
                                                </div>
                                            </div>
                                        )}
                                        <ConfirmPrompt
                                            show={deleteReportId !== null}
                                            handleClose={() => setDeleteReportId(null)}
                                            handleConfirm={async () => {
                                                await deleteMusicTestReport(deleteReportId);
                                            }}
                                        />
                                    </div>
                                )}
                            </>
                        )
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export default MusicTestReports;
